import { useState } from "react";
import colors from "../utils/index.module.scss";

interface Props {
    id: string
    infoText?: string
    disabled?: boolean
    error?: boolean
    multiple?: boolean
    size?: number
    onChange?: (event: File[]) => void
}

export function FileInputComponent(props: Props) {
    const { id } = props
    const { infoText } = props
    const { disabled } = props
    const { error } = props
    const { multiple } = props
    const { onChange } = props
    const { size } = props

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const [dragged, setDragged] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            onChange && onChange(Array.from(event.target.files))
        }
    };

    const max = 10 * 1024 * 1024

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!disabled) {
            setDragged(false);
            const files = event.dataTransfer.files;
            onChange && onChange(Array.from(files));
        }
    };

    return (
        <div style={{ width: '100%', opacity: (dragged ? 0.5 : 1) }}>
            <div
                id={id}
                draggable
                onClick={() => inputRef?.click()}
                onDragOver={e => {
                    if (!disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.dataTransfer.dropEffect = 'copy';
                        !disabled && setDragged(true);
                    }
                }}
                onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!disabled) {
                        setDragged(true);
                    }
                }}
                onDragLeave={e => {
                    if (!disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        !disabled && setDragged(false);
                    }
                }}
                onDrop={handleDrop}
                style={{
                    padding: '8px',
                    width: '100%',
                    borderRadius: '8px',
                    backgroundColor: disabled ? colors['neutral40'] : colors['white'],
                    border: '1px dashed ' + colors.neutral100,
                    display: "flex",
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    cursor: 'pointer',
                    gap: '16px'
                }}
            >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="8" fill="#F3F4F6" />
                    <path d="M19.3334 19.3334L16 16M16 16L12.6667 19.3334M16 16V23.5M22.9917 21.325C23.8045 20.8819 24.4466 20.1808 24.8166 19.3322C25.1866 18.4837 25.2635 17.5361 25.0352 16.6389C24.8069 15.7418 24.2863 14.9463 23.5556 14.3779C22.8249 13.8095 21.9257 13.5006 21 13.5H19.95C19.6978 12.5244 19.2277 11.6186 18.575 10.8509C17.9223 10.0831 17.1041 9.47324 16.1818 9.0672C15.2595 8.66116 14.2572 8.46949 13.2501 8.5066C12.2431 8.5437 11.2576 8.80861 10.3677 9.28142C9.47777 9.75422 8.70662 10.4226 8.11221 11.2363C7.5178 12.0501 7.1156 12.988 6.93584 13.9795C6.75609 14.9711 6.80345 15.9905 7.07437 16.9611C7.3453 17.9317 7.83273 18.8282 8.50003 19.5834" stroke="#5E6482" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="text text--md typography--medium typography-neutral--800">
                    {'Drag & drop files or '}
                    <span className='typography-primary--500'>
                        browse files
                    </span>
                </span>
                <input draggable name={id} id={id} ref={ref => { if (!disabled) { setInputRef(ref) } }} multiple={multiple} type="file" style={{ display: 'none' }} onChange={handleChange} />
            </div>
            <span className={"text text--md typography--regular " + (size && size > max ? ' typography-destructive--400' : ' typography-neutral--400')}>Dimensione massima del file: 10 MB</span>
        </div>
    )
}