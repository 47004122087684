import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NewObjectService } from "../objects/service";
import { PromiseStatuses } from "../utils";
import { ChatDTO, ChatFilters, CompanyUnseenMessageDTO, MessageResponse, UserInfoDTO } from "./dto";
import { NewChatService } from "./service";

interface ChatsState {
    findChatsStatus: PromiseStatuses
    findChatResponse: ChatDTO[]
    findChatUserStatus: PromiseStatuses
    findChatUserResponse: UserInfoDTO[]
    findChatAvatarStatus: PromiseStatuses
    findChatAvatarResponse: { userId: string, objectId: string | null }[]
    findMessagesStatus: PromiseStatuses
    findMessagesResponse?: MessageResponse
    selectedChat: number
    avatarInfos?: { first: string, second: string, third: string, userId: string }
    usersListAfterSend: string[]
    setChatSeenStatus: PromiseStatuses
    companyUnseenMessagesResponse: CompanyUnseenMessageDTO[]
    companyUnseenMessagesStatus: PromiseStatuses
}

const initialState: ChatsState = {
    findChatsStatus: 'idle',
    findChatResponse: [],
    findChatUserStatus: 'idle',
    findChatUserResponse: [],
    findChatAvatarStatus: 'idle',
    findChatAvatarResponse: [],
    findMessagesStatus: 'idle',
    selectedChat: 0,
    usersListAfterSend: [],
    setChatSeenStatus: 'idle',
    companyUnseenMessagesResponse: [],
    companyUnseenMessagesStatus: 'idle'
}

export const findChats = createAsyncThunk(
    'chat/findChats',
    async (filters: ChatFilters): Promise<ChatDTO[]> => {
        const chatsService = NewChatService()

        return chatsService.findChats(filters)
    },
)

export const setChatSeen = createAsyncThunk(
    'chat/chatSeen',
    async (id: number): Promise<void> => {
        const chatsService = NewChatService()

        return chatsService.setChatSeen(id)
    },
)

export const findChatUsers = createAsyncThunk(
    'chat/findChatUsers',
    async (userIds: string[]): Promise<UserInfoDTO[]> => {
        let promises: Promise<UserInfoDTO>[] = []
        promises = userIds.map(async (user) => {
            if (user === '') {
                return (Promise.resolve({
                    name: '',
                    surname: '',
                    role: '',
                    mail: '',
                    avatarObjectId: null,
                    userId: 'utente non disponibile',
                    businessName: ''
                }))
            }
            const chatsService = NewChatService()

            return chatsService.findChatUser(user)
                .catch(err => {
                    return (Promise.resolve({
                        name: '',
                        surname: '',
                        role: '',
                        mail: '',
                        avatarObjectId: null,
                        userId: 'utente non disponibile',
                        businessName: ''
                    }))
                })
        })
        return Promise.all(promises)
    },
)

export const findChatImages = createAsyncThunk(
    'chat/findChatImages',
    async (request: { userId: string, objectId: string | null }[]): Promise<{ userId: string, objectId: string | null }[]> => {
        let promises: Promise<{ userId: string, objectId: string | null }>[] = []
        promises = request.map(async (user) => {
            if (user.objectId !== null) {
                const objectService = NewObjectService()
                return objectService.findFileByIdWithUserId(user.objectId, user.userId)
            } else {
                return Promise.resolve({ userId: user.userId, objectId: '' })
            }
        })
        return Promise.all(promises)
    },
)

export const findMessages = createAsyncThunk(
    'chat/findMessages',
    async (request: { chatId: number, page: number, itemsPerPage: number }): Promise<MessageResponse> => {
        const chatsService = NewChatService()

        return chatsService.findMessages(request.chatId, request.page, request.itemsPerPage)
    },
)

export const getCompanyUnseenInfoMessages = createAsyncThunk(
    'chat/getCompanyUnseenInfoMessages',
    async (topic: string): Promise<CompanyUnseenMessageDTO[]> => {
        const chatsService = NewChatService()

        return chatsService.getCompanyUnseenInfoMessages(topic)
    },
)


const chatSlice = createSlice({
    name: 'chat/slice',
    initialState,
    reducers: {
        setSelectedChat: (state, action) => {
            state.selectedChat = action.payload
        },
        setAvatarInfos: (state, action) => {
            state.avatarInfos = action.payload
        },
        setUsersListAfterSend: (state, action) => {
            state.usersListAfterSend = action.payload
        },
        setChatSeenStatus: (state, action) => {
            state.setChatSeenStatus = action.payload
        },
        setCompaniesUnseenMessagesStatus: (state, action) => {
            state.companyUnseenMessagesStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findChats.pending, (state) => {
                state.findChatsStatus = 'loading'
            })
            .addCase(findChats.fulfilled, (state, action) => {
                state.findChatsStatus = 'successfully'
                state.findChatResponse = action.payload
            })
            .addCase(findChats.rejected, (state) => {
                state.findChatsStatus = 'failed'
            })
            .addCase(findChatUsers.pending, (state) => {
                state.findChatUserStatus = 'loading'
            })
            .addCase(findChatUsers.fulfilled, (state, action) => {
                state.findChatUserStatus = 'successfully'
                state.findChatUserResponse = action.payload
            })
            .addCase(findChatUsers.rejected, (state) => {
                state.findChatUserStatus = 'failed'
            })
            .addCase(findChatImages.pending, (state) => {
                state.findChatAvatarStatus = 'loading'
            })
            .addCase(findChatImages.fulfilled, (state, action) => {
                state.findChatAvatarStatus = 'successfully'
                state.findChatAvatarResponse = action.payload
            })
            .addCase(findChatImages.rejected, (state) => {
                state.findChatAvatarStatus = 'failed'
            })
            .addCase(findMessages.pending, (state) => {
                state.findMessagesStatus = 'loading'
            })
            .addCase(findMessages.fulfilled, (state, action) => {
                state.findMessagesStatus = 'successfully'
                state.findMessagesResponse = action.payload
            })
            .addCase(findMessages.rejected, (state) => {
                state.findMessagesStatus = 'failed'
            })
            .addCase(setChatSeen.pending, (state) => {
                state.setChatSeenStatus = 'loading'
            })
            .addCase(setChatSeen.fulfilled, (state, action) => {
                state.setChatSeenStatus = 'successfully'
            })
            .addCase(setChatSeen.rejected, (state) => {
                state.setChatSeenStatus = 'failed'
            })
            .addCase(getCompanyUnseenInfoMessages.pending, (state) => {
                state.companyUnseenMessagesStatus = 'loading'
            })
            .addCase(getCompanyUnseenInfoMessages.fulfilled, (state, action) => {
                state.companyUnseenMessagesStatus = 'successfully'
                state.companyUnseenMessagesResponse = action.payload
            })
            .addCase(getCompanyUnseenInfoMessages.rejected, (state) => {
                state.companyUnseenMessagesStatus = 'failed'
            })
    },
})

export const {
    setSelectedChat,
    setAvatarInfos,
    setUsersListAfterSend,
    setChatSeenStatus,
    setCompaniesUnseenMessagesStatus
} = chatSlice.actions

export default chatSlice.reducer
