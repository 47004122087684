import { ReactElement, cloneElement, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { HeadingSize, HeadingVerticalAlign, headingSizeMap, headingTitleMap } from "./dto";

interface Props {
    label: string,
    buttons: ReactElement[]
    size: HeadingSize
    subLabel?: string
    verticalAlign?: HeadingVerticalAlign
}

export function HeadingComponent(props: Props) {
    const { label } = props
    const { buttons } = props
    const { size } = props
    const { subLabel } = props
    const { verticalAlign } = props

    const [parsedButton, setParsedButton] = useState<ReactElement[]>([])

    useEffect(() => {
        let newButtonList = [...buttons]
        if (buttons.length > 0) {
            setParsedButton([])
            newButtonList.forEach((button, index) => newButtonList[index] = cloneElement(button, { size: headingSizeMap.get(size), key: uuidv4() }))
        }
        setParsedButton(newButtonList)
    }, [buttons])

    return (
        <div className={"row m-0 p-0 justify-content-between " + (verticalAlign === HeadingVerticalAlign.CENTER ? 'align-items-center' : 'align-items-start')} style={{ width: '100%' }}>
            <div className={"col-12 col-lg m-0 p-0 d-flex " + (verticalAlign === HeadingVerticalAlign.CENTER ? 'align-items-center' : 'align-items-start')} >
                <p className="m-0 p-0" style={{ textAlign: 'left' }}>
                    <span className={headingTitleMap.get(size)}>{label}</span>
                    <br />
                    <span className="text text--lg typography--regular typography-neutral--500 text-left">{subLabel}</span>
                </p>
            </div>
            {
                parsedButton.length > 0 && (
                    <div className="col-12 col-lg-auto m-0 p-0">
                        <div className="d-flex flex-row" style={{ gap: '12px' }}>{parsedButton}</div>
                    </div>
                )
            }
        </div>
    )

}