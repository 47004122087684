import { ReactElement, cloneElement } from "react"
import colors from '../utils/index.module.scss'

export enum CounterColor {
    PRIMARY = 'PRIMARY',
    ORCHID = 'ORCHID',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    ERROR = 'ERROR'
}
interface Props {
    title: string
    toCount: number
    onClick?: Function
    icon: ReactElement
    color: CounterColor
}

const colorMap = new Map<CounterColor, { background: string, number: string, label: string, icon: string }>([
    [CounterColor.PRIMARY, {
        background: '#EBF2FF', number: '#002466', label: '#0047CC', icon: colors.primary600
    }],
    [CounterColor.ORCHID, {
        background: '#EFDEF8', number: '#3D1254', label: '#7A24A8', icon: '#7A24A8'
    }],
    [CounterColor.SUCCESS, {
        background: '#F0FAF0', number: '#1A4D1A', label: '#339933', icon: '#339933'
    }],
    [CounterColor.WARNING, {
        background: '#FFF1D6', number: '#664300', label: '#CC8600', icon: '#CC8600'
    }],
    [CounterColor.ERROR, {
        background: '#FEECEE', number: '#61050F', label: '#C20A1F', icon: '#C20A1F'
    }]
])
export function CounterSecondaryComponent(props: Props) {
    const { title } = props
    const { toCount } = props
    const { onClick } = props
    const { icon } = props
    const { color } = props

    return (
        <div
            style={{
                display: "flex",
                padding: "8px 8px 8px 12px",
                flex: "1 0 0",
                alignItems: "center",
                justifyContent: 'space-between',
                gap: "8px",
                borderRadius: "12px",
                backgroundColor: colorMap.get(color)?.background
            }}
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                justifyContent: 'flex-start'
            }}>
                <div
                    style={{
                        width: "32px",
                        fontFamily: "Figtree",
                        fontSize: "24px",
                        lineHeight: "28.8px",
                        fontWeight: "700",
                        color: colorMap.get(color)?.number
                    }}
                >
                    {toCount}
                </div>
                <div
                    style={{
                        textAlign: 'left',
                        fontFamily: "Figtree",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "12px",
                        color: colorMap.get(color)?.label
                    }}
                >
                    {title}
                </div>
            </div>
            <div
                onClick={() => onClick && onClick()}
                style={{
                    height: "36px",
                    width: "36px",
                    borderRadius: "8px",
                    padding: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255, 255, 255, 0.64)",
                }}>
                {cloneElement(icon, { colorBase: colorMap.get(color)?.icon })}
            </div>
        </div>
    )
}