import { SxProps } from "@mui/material"
import { ButtonColorsMap, Colors, IconStyle, Size, Variant } from "./dto"

export function buttonStyle(color: Colors, variant: Variant, size: Size, iconStyle: IconStyle) {
    const schemeColor = ButtonColorsMap.get(color)

    const basicStyle: SxProps = {
        lineHeight: '24px',
        borderRadius: '8px',
        textTransform: 'none',
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: 500,
        padding: '0 16px',
        minWidth: '24px'
    }

    const variantMap = new Map<Variant, SxProps>([
        [Variant.SOLID, {
            backgroundColor: schemeColor?.base,
            color: schemeColor?.lighter,
            '&:hover': {
                backgroundColor: schemeColor?.dark,
                color: schemeColor?.lighter,
            }, '&:focused': {
                backgroundColor: schemeColor?.dark,
                color: schemeColor?.lighter,
            },
            '&:disabled': {
                backgroundColor: schemeColor?.base,
                color: schemeColor?.lighter,
                opacity: '50%'
            }
        }],
        [Variant.OUTLINE, {
            backgroundColor: color === Colors.NEUTRAL ? schemeColor?.lighter : schemeColor?.light,
            color: schemeColor?.base,
            border: '1px solid ' + (color === Colors.NEUTRAL ? schemeColor?.light : schemeColor?.base),
            '&:hover': {
                backgroundColor: color === Colors.NEUTRAL ? schemeColor?.lighter : schemeColor?.light,
                color: schemeColor?.dark,
                border: '1px solid ' + (color === Colors.NEUTRAL ? schemeColor?.light : schemeColor?.dark),
            }, '&:focused': {
                backgroundColor: color === Colors.NEUTRAL ? schemeColor?.lighter : schemeColor?.light,
                color: schemeColor?.dark,
                border: '1px solid ' + (color === Colors.NEUTRAL ? schemeColor?.light : schemeColor?.dark),
            },
            '&:disabled': {
                backgroundColor: schemeColor?.light,
                color: schemeColor?.base,
                border: '1px solid ' + (color === Colors.DESTRUCTIVE ? schemeColor?.base : schemeColor?.light),
                opacity: '50%'
            }
        }],
        [Variant.GHOST, {
            backgroundColor: schemeColor?.lighter,
            color: schemeColor?.base,
            border: '1px solid ' + schemeColor?.light,
            '&:hover': {
                backgroundColor: schemeColor?.lighter,
                color: schemeColor?.dark,
                border: '1px solid ' + schemeColor?.light,
            }, '&:focused': {
                backgroundColor: schemeColor?.lighter,
                color: schemeColor?.dark,
                border: '1px solid ' + schemeColor?.light,
            },
            '&:disabled': {
                backgroundColor: schemeColor?.lighter,
                color: schemeColor?.dark,
                border: '1px solid ' + schemeColor?.light,
                opacity: '50%'
            }
        }],
        [Variant.LINK, {
            color: schemeColor?.base,
            '&:hover': {
                color: schemeColor?.dark,
                backgroundColor: null
            }, '&:focused': {
                color: schemeColor?.dark,
                backgroundColor: null
            },
            '&:disabled': {
                color: schemeColor?.dark,
                opacity: '50%',
                backgroundColor: null
            }
        }]
    ])

    const sizeMap = new Map<Size, SxProps>([
        [Size.SM, {
            fontSize: '14px',
            height: '32px',
            width: iconStyle === IconStyle.ONLY ? '32px' : 'auto'
        }],
        [Size.MD, {
            fontSize: '16px',
            height: '40px',
            width: iconStyle === IconStyle.ONLY ? '40px' : 'auto'
        }],
        [Size.LG, {
            fontSize: '18px',
            height: '48px',
            width: iconStyle === IconStyle.ONLY ? '48px' : 'auto'
        }],
        [Size.FIT, {
            fontSize: '14px',
            height: '32px',
            width: '100%'
        }]
    ])

    return Object.assign({}, basicStyle, variantMap.get(variant), sizeMap.get(size))
}

export function colorIcon(color: Colors, variant: Variant) {
    const schemeColor = ButtonColorsMap.get(color)

    if (variant === Variant.SOLID) {
        return schemeColor?.lighter
    } else {
        return schemeColor?.base
    }
}