import { useEffect, useState } from "react"
import { IconsProps } from "./dto"

export function EditIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_243_2607)">
                <path d="M9.16663 3.3332H3.33329C2.89127 3.3332 2.46734 3.50879 2.15478 3.82135C1.84222 4.13391 1.66663 4.55784 1.66663 4.99986V16.6665C1.66663 17.1086 1.84222 17.5325 2.15478 17.845C2.46734 18.1576 2.89127 18.3332 3.33329 18.3332H15C15.442 18.3332 15.8659 18.1576 16.1785 17.845C16.491 17.5325 16.6666 17.1086 16.6666 16.6665V10.8332M15.4166 2.0832C15.7481 1.75168 16.1978 1.56543 16.6666 1.56543C17.1355 1.56543 17.5851 1.75168 17.9166 2.0832C18.2481 2.41472 18.4344 2.86436 18.4344 3.3332C18.4344 3.80204 18.2481 4.25168 17.9166 4.5832L9.99996 12.4999L6.66663 13.3332L7.49996 9.99986L15.4166 2.0832Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_243_2607">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}