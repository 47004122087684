import { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ChatViewV2 } from "../chatV2";
import { DashboardView } from "../dashboard";
import { DocumentsView } from "../documents";
import { BalanceView } from "../fiscalDocument/balance";
import { EmployeeView } from "../fiscalDocument/employee";
import { F24View } from "../fiscalDocument/f24";
import { OthersView } from "../fiscalDocument/others";
import { keycloak } from "../keycloak";
import { ProfileView } from "../profile";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { setMenuItems } from "../sidebar/slice";
import { SpinnerComponent } from "../spinner";

const router = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    },
    {
        path: '/documents',
        element: (<DocumentsView />)
    },
    {
        path: '/messages',
        element: (<ChatViewV2 />)
    },
    {
        path: '/profile',
        element: (<ProfileView />)
    },
])

const premiumRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    },
    {
        path: '/documents',
        element: (<DocumentsView />)
    },
    {
        path: '/messages',
        element: (<ChatViewV2 />)
    },
    {
        path: '/profile',
        element: (<ProfileView />)
    },
    {
        path: '/f24',
        element: (<F24View />)
    },
    {
        path: '/balance',
        element: (<BalanceView />)
    },
    {
        path: '/employee',
        element: (<EmployeeView />)
    },
    {
        path: '/others',
        element: (<OthersView />)
    },
])

export function KeycloakRouter() {
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (keycloak.token !== undefined && authState.findMeResponse !== undefined) {
            if (authState.findMeResponse.premium) {
                dispatch(setMenuItems([MenuItems.DASHBOARD, MenuItems.DOCUMENTS, MenuItems.CHAT, MenuItems.F24, MenuItems.BALANCE, MenuItems.EMPLOYEE, MenuItems.OTHER]))
            } else {
                dispatch(setMenuItems([MenuItems.DASHBOARD, MenuItems.DOCUMENTS, MenuItems.CHAT]))
            }
        }
    }, [keycloak.token])

    if (authState.findMeStatus === "loading") {
        return (<SpinnerComponent fullScreen size={"small"} />)
    }

    return (
        <RouterProvider router={authState.findMeResponse?.premium ? premiumRouter : router} />
    );
}