import { TableHead, TableRow } from "@mui/material";
import { ReactNode } from "react";
import './style.scss';

interface Props {
    children: ReactNode
    border?: boolean
}

export function TableHeadComponent(props: Props) {
    const { children } = props
    const { border } = props

    return (
        <TableHead
            classes={{ root: 'table-head-custom ' + (border ? 'table-head-custom-border' : '') }}
        >
            <TableRow
            >
                {children}
            </TableRow>
        </TableHead >
    )
}