import { Tab } from "@mui/material";
import { useEffect } from "react";
import { EmptyList } from "../emptyList/emptyList";
import { HeadingSize } from "../heading/dto";
import { HorizontalTabPrimaryComponent } from "../horizontalTabPrimary";
import { TabPanelComponent } from "../horizontalTabPrimary/tabPanel";
import { LayoutComponent } from "../layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { DocumentReceived } from "./documentReceived";
import { DocumentSent } from "./documentSent";
import { findAllReceivedDocuments, findAllSentDocuments, getCompanyUnseenInfoDocuments, setDeleteDocumentStatus, setDocumentTabValue } from "./slice";

export function DocumentsView() {
    const documentState = useAppSelector(state => state.document)
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    const handleChangeDocuments = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setDocumentTabValue(newValue))
    };

    useEffect(() => {
        if (documentState.deleteDocumentStatus === 'successfully') {
            dispatch(getCompanyUnseenInfoDocuments())
            if (authState.findMeResponse !== undefined) {
                dispatch(findAllReceivedDocuments({ companyId: authState.findMeResponse.id, year: documentState.documentReceivedFilters.year, period: documentState.documentReceivedFilters.period, page: documentState.documentReceivedFilters.page }))
                dispatch(findAllSentDocuments({ companyId: authState.findMeResponse.id, period: documentState.documentSentFilters.period, year: documentState.documentSentFilters.year, page: documentState.documentSentFilters.page }))
            }
            dispatch(setDeleteDocumentStatus('idle'))
        }
    }, [documentState.deleteDocumentStatus])

    return (
        <LayoutComponent
            headingLabel={"Documenti"}
            headingButtons={[]}
            breadcrumbItems={["Documenti"]}
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.DOCUMENTS}
        >
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <HorizontalTabPrimaryComponent
                    negativeMargin={36}
                    value={documentState.documentTabValue}
                    handleChange={handleChangeDocuments}
                >
                    <Tab label="Ricevuti" value={0} />
                    <Tab label="Inviati" value={1} />
                </HorizontalTabPrimaryComponent>
            </div>
            <div style={{ marginTop: '16px' }}>
                <TabPanelComponent value={documentState.documentTabValue} index={0}>
                    <DocumentReceived />
                </TabPanelComponent>
                <TabPanelComponent value={documentState.documentTabValue} index={1}>
                    <DocumentSent />
                </TabPanelComponent>
            </div>
            {
                ((documentState.findAllRecivedDocumentsResponse &&
                    documentState.findAllRecivedDocumentsResponse.total === 0 &&
                    documentState.documentTabValue === 0) ||
                    (documentState.findAllSentDocumentsResponse &&
                        documentState.findAllSentDocumentsResponse.total === 0 &&
                        documentState.documentTabValue === 1)) &&
                < EmptyList />
            }
        </LayoutComponent>
    )
}