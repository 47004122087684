import { Pagination, PaginationItem } from "@mui/material";
import { ChevronDownIcon } from "../icons/chevronDown";
import colors from '../utils/index.module.scss';
import './style.scss';

function Previous() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '8px',
                gap: '8px'
            }}
        >
            <div
                style={{
                    transform: 'rotate(90deg)'
                }}>
                <ChevronDownIcon colorBase={colors.primary700} />
            </div>
            <span className="text text--md typography--regular typography-primary--800">
                Precedente
            </span>
        </div>
    );
}

function Next() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '8px',
                gap: '8px'
            }}
        >
            <span className="text text--md typography--regular typography-primary--800">
                Prossimo
            </span>
            <div
                style={{
                    transform: 'rotate(-90deg)'
                }}>
                <ChevronDownIcon colorBase={colors.primary700} />
            </div>
        </div>
    );
}

interface Props {
    count: number
    page: number
    onChange: (page: number) => void
}

export function PaginationComponent(props: Props) {
    const { count } = props
    const { page } = props
    const { onChange } = props

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '44px'
            }}
        >
            <Pagination
                onChange={(_, page) => onChange(page - 1)}
                count={count}
                page={page + 1}
                sx={{
                    padding: '8px',
                    gap: '12px'
                }}
                shape="rounded"
                renderItem={item => {
                    return (
                        <PaginationItem
                            classes={{
                                text: 'default-pagination',
                                selected: 'selected-pagination',
                                ellipsis: 'ellipsis'
                            }}
                            slots={{
                                previous: Previous,
                                next: Next
                            }}
                            {...item}
                        />
                    )
                }}
            />
        </div>
    )
}