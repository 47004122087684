export enum EventTypeEnum {
    Other = 'Other',
    F24 = 'F24'
}

export interface EventsFilters {
    fromDate: string
    toDate: string
    itemsPerPage: number
    page: number
    companyId: number
}

export interface NewEventDTO {
    name?: string,
    date?: string,
    note?: string
    type: EventTypeEnum,
    companyId?: number
}

export interface EventDTO {
    id: number,
    name: string,
    note: string | null,
    userId: string,
    date: Date,
    type: EventTypeEnum,
    companyId: number,
    fiscalDocumentId: number | null,
    status: EventStatusEnum
}

export interface FindAllEventsDTO {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: EventDTO[]
}

export interface EditEventDTO {
    name?: string,
    date?: string,
    note?: string
}

export enum EventStatusEnum {
    NotResolved = 'NotResolved',
    Resolved = 'Resolved'
}

export interface UpdateEventStatusRequest {
    status: EventStatusEnum
}