import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { BreadcrumbsIcon } from "../icons/breadcrumbs";
import { useAppSelector } from "../redux/hooks";
import { setOpen } from "../sidebar/slice";
import colors from '../utils/index.module.scss';

interface Props {
    items: string[]
}
export function Breadcrumb(props: Props) {
    const { items } = props

    let breadcrumbItems: ReactNode[] = []

    const open = useAppSelector(state => state.sidebar.open)

    const dispatch = useDispatch()

    const handleDrawerOpen = () => {
        dispatch(setOpen(true));
    };

    const handleDrawerClose = () => {
        dispatch(setOpen(false));
    };

    items.forEach((item, index) => {
        breadcrumbItems.push(
            <p key={item + index} style={{ margin: 0 }} className={"text text--md typography--medium" + ((index + 1) === items.length ? " typography-primary--500" : " text-neutral--200")}>
                {item}
                {
                    (index + 1) !== items.length && (
                        <span
                            style={{ marginLeft: '8px', marginRight: '8px' }}
                            className="text text--md typography--medium text-neutral--200">
                            /
                        </span>
                    )
                }
            </p>
        )
    })

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div onClick={open ? handleDrawerClose : handleDrawerOpen} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <BreadcrumbsIcon colorBase={open ? colors.neutral400 : colors.primary500} colorHover={colors.neutral200} />
            </div>
            <div style={{ marginRight: '8px' }} />
            {breadcrumbItems}
        </div>
    )
}