import { useState } from "react";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { TrashIcon } from "../icons/trash";
import { keycloak } from "../keycloak";
import { DocumentsResponseDTO } from "../objects/dto";
import { NewObjectService } from "../objects/service";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { deleteDocument, setDeleteDocumentOpenModal } from "./slice";

interface Props {
    document?: DocumentsResponseDTO
}

export const getMimeTypeFromBase64 = (file: string): string | undefined => {
    const removeData = file.split('data:')[1]
    return removeData.split(';')[0]
};

export function CompanyMenuDocumentsComponent(props: Props) {
    const { document } = props

    const dispatch = useAppDispatch()
    const objectService = NewObjectService()

    const objectsState = useAppSelector(state => state.object)
    const companyState = useAppSelector(state => state.document)
    const authState = useAppSelector(state => state.auth)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (document === undefined) {
        return <></>
    }

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            {
                document.userId === keycloak.subject &&
                <MenuItemComponent
                    onClick={() => { dispatch(setDeleteDocumentOpenModal(true)) }}>
                    <TrashIcon colorBase={colors.neutral600} />
                    Cancella
                </MenuItemComponent>
            }
            <ConfirmOrDenyModal
                open={companyState.deleteDocumentOpenModal}
                handleClose={() => { dispatch(setDeleteDocumentOpenModal(false)); handleClose() }}
                title={"Elimina documento"}
                description={"Sei sicuro di voler eliminare questo documento?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    dispatch(deleteDocument(document.id.toString()))
                    dispatch(setDeleteDocumentOpenModal(false))
                    handleClose()
                }}
            />
        </ContextMenuComponent>
    )
}