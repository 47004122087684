import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ActivityCategory, FindAllActivityType } from "../activity/dto"
import { NewActivityService } from "../activity/service"
import { DocumentsResponseDTO, FindAllDocumentsResponse } from "../objects/dto"
import { NewObjectService } from "../objects/service"
import { PromiseStatuses } from "../utils"
import { ActivityTypeDTO, CompanyResponseDTO, CompanyUnseenInfoDTO, FindAllAtecos } from "./dto"
import { NewCompanyService } from "./service"

interface CompanyState {
    documentTabValue: number
    findCompanyResponse?: CompanyResponseDTO
    findCompanyStatus: PromiseStatuses
    findAllAtecosResponse?: FindAllAtecos
    findAllAtecosStatus: PromiseStatuses
    findCompanyCategoryStatus: PromiseStatuses,
    findCompanyCategoryResponse: ActivityCategory[]
    findCompanyTypesStatus: PromiseStatuses,
    findCompanyTypesResponse: FindAllActivityType[]
    findActivityTypeByIdStatus: PromiseStatuses
    findActivityTypeByIdResponse?: ActivityTypeDTO[]
    openSendDocumentModal: boolean
    findAllSentDocumentsResponse?: FindAllDocumentsResponse
    findAllSentDocumentsStatus: PromiseStatuses
    findAllRecivedDocumentsResponse?: FindAllDocumentsResponse
    findAllReceivedDocumentsStatus: PromiseStatuses
    findDocumentByIdResponse?: DocumentsResponseDTO
    findDocumentByIdStatus: PromiseStatuses
    deleteDocumentOpenModal: boolean
    deleteDocumentStatus: PromiseStatuses
    documentSentFilters: {
        period: number | ''
        year: number,
        page: number
    },
    documentReceivedFilters: {
        period: number | ''
        year: number,
        page: number
    }
    companiesUnseenDocumentsStatus: PromiseStatuses
    companiesUnseenDocumentsResponse: CompanyUnseenInfoDTO[],
}

const initialState: CompanyState = {
    documentTabValue: 0,
    findCompanyStatus: 'idle',
    findAllAtecosStatus: 'idle',
    findCompanyCategoryStatus: 'idle',
    findCompanyCategoryResponse: [],
    findCompanyTypesStatus: 'idle',
    findCompanyTypesResponse: [],
    findActivityTypeByIdStatus: 'idle',
    openSendDocumentModal: false,
    findAllReceivedDocumentsStatus: 'idle',
    findAllSentDocumentsStatus: 'idle',
    findDocumentByIdStatus: 'idle',
    deleteDocumentOpenModal: false,
    deleteDocumentStatus: 'idle',
    documentSentFilters: {
        period: '',
        year: new Date().getFullYear(),
        page: 0
    },
    documentReceivedFilters: {
        period: '',
        year: new Date().getFullYear(),
        page: 0
    },
    companiesUnseenDocumentsResponse: [],
    companiesUnseenDocumentsStatus: 'idle',
}

export const getCompanyUnseenInfoDocuments = createAsyncThunk(
    'company/getCompanyUnseenInfoDocuments',
    async (): Promise<CompanyUnseenInfoDTO[]> => {
        const companyService = NewCompanyService()

        return companyService.getCompanyUnseenInfoDocuments()
    }
)

export const findCompanyById = createAsyncThunk(
    'company/findCompanyBycompanySettedId',
    async (request: string, thunkApi): Promise<CompanyResponseDTO> => {
        const companyService = NewCompanyService()

        return companyService.findCompany(request)
    },
)

export const findCompanyActivityCategories = createAsyncThunk(
    'company/findCompanyActivityCategories',
    async (request: number[], thunkApi): Promise<ActivityCategory[]> => {
        let promises: Promise<ActivityCategory>[] = []
        promises = request.map(async (id) => {
            const activitySerivce = NewActivityService()

            return activitySerivce.findActivityCategory(id)
        })
        return Promise.all(promises)
    }
)

export const findCompanyActivityTypes = createAsyncThunk(
    'company/findCompanyActivityTypes',
    async (request: number[], thunkApi): Promise<FindAllActivityType[]> => {
        let promises: Promise<FindAllActivityType>[] = []
        promises = request.map(async (id) => {
            const activitySerivce = NewActivityService()

            return activitySerivce.findAllActivityType({ name: '', activityCategoryId: id.toString() })
        })
        return Promise.all(promises)
    }
)

export const findAllAtecos = createAsyncThunk(
    'company/findAllAtecos',
    async (_, thunkApi): Promise<FindAllAtecos> => {
        const companyService = NewCompanyService()

        return companyService.findAllAtecos()
    }
)


export const findAllSentDocuments = createAsyncThunk(
    'company/findAllSentDocuments',
    async (request: { companyId: number, year: number, period: number | '', page: number }, thunkApi): Promise<FindAllDocumentsResponse> => {
        const companyService = NewCompanyService()

        return companyService.findAllDocuments(request.companyId, request.year, request.period, request.page, 15, 'false')
    }
)

export const findAllReceivedDocuments = createAsyncThunk(
    'company/findAllReceivedDocuments',
    async (request: { companyId: number, year: number, period: number | '', page: number }, thunkApi): Promise<FindAllDocumentsResponse> => {
        const companyService = NewCompanyService()

        return companyService.findAllDocuments(request.companyId, request.year, request.period, request.page, 15, 'true')
    }
)

export const findAllSentDocumentsCounter = createAsyncThunk(
    'company/findAllSentDocumentsCounter',
    async (request: { companyId: number, year: number }, thunkApi): Promise<FindAllDocumentsResponse> => {
        const companyService = NewCompanyService()

        return companyService.findAllDocuments(request.companyId, request.year, '', 0, 0, 'false')
    }
)

export const findAllReceivedDocumentsCounter = createAsyncThunk(
    'company/findAllReceivedDocumentsCounter',
    async (request: { companyId: number, year: number }, thunkApi): Promise<FindAllDocumentsResponse> => {
        const companyService = NewCompanyService()

        return companyService.findAllDocuments(request.companyId, request.year, '', 0, 0, 'true')
    }
)

export const findDocumentById = createAsyncThunk(
    'company/findDocumentById',
    async (id: string, thunkApi): Promise<DocumentsResponseDTO> => {
        const companyService = NewCompanyService()

        return companyService.findDocumentById(id)
    }
)

export const deleteDocument = createAsyncThunk(
    'company/deleteDocument',
    async (id: string, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.deleteDocument(id)
    }
)

export const findLoans = createAsyncThunk(
    'company/findLoans',
    async (request: string[], thunkApi): Promise<string[]> => {
        let promises: Promise<string>[] = []
        promises = request.map(async (id) => {
            const objectService = NewObjectService()

            return objectService.findFileById(id)
        })
        return Promise.all(promises)
    }
)

export const findFundings = createAsyncThunk(
    'company/findFundings',
    async (request: string[], thunkApi): Promise<string[]> => {
        let promises: Promise<string>[] = []
        promises = request.map(async (id) => {
            const objectService = NewObjectService()

            return objectService.findFileById(id)
        })
        return Promise.all(promises)
    }
)

export const findLeasings = createAsyncThunk(
    'company/findLeasings',
    async (request: string[], thunkApi): Promise<string[]> => {
        let promises: Promise<string>[] = []
        promises = request.map(async (id) => {
            const objectService = NewObjectService()

            return objectService.findFileById(id)
        })
        return Promise.all(promises)
    }
)

const companySlice = createSlice({
    name: 'company/slice',
    initialState,
    reducers: {

        setDocumentTabValue: (state, action) => {
            state.documentTabValue = action.payload
        },
        setFindCompanyStatus: (state, action) => {
            state.findCompanyStatus = action.payload
        },
        setOpenDocumentModal: (state, action) => {
            state.openSendDocumentModal = action.payload
        },
        setFindDocumentStatus: (state, action) => {
            state.findDocumentByIdStatus = action.payload
        },
        setDeleteDocumentOpenModal: (state, action) => {
            state.deleteDocumentOpenModal = action.payload
        },
        setDocumentSentFilterPeriod: (state, action) => {
            state.documentSentFilters.period = action.payload
        },
        setDocumentSentFilterYear: (state, action) => {
            state.documentSentFilters.year = action.payload
        },
        setDocumentSentFilterPage: (state, action) => {
            state.documentSentFilters.page = action.payload
        },
        setDocumentReceivedFilterPeriod: (state, action) => {
            state.documentReceivedFilters.period = action.payload
        },
        setDocumentReceivedFilterYear: (state, action) => {
            state.documentReceivedFilters.year = action.payload
        },
        setDocumentReceivedFilterPage: (state, action) => {
            state.documentReceivedFilters.page = action.payload
        },
        setDeleteDocumentStatus: (state, action) => {
            state.deleteDocumentStatus = action.payload
        },
        setFindAllReceivedDocumentsStatus: (state, action) => {
            state.findAllReceivedDocumentsStatus = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(findCompanyById.pending, (state) => {
                state.findCompanyStatus = 'loading'
            })
            .addCase(findCompanyById.fulfilled, (state, action) => {
                state.findCompanyStatus = 'successfully'
                state.findCompanyResponse = action.payload
            })
            .addCase(findCompanyById.rejected, (state) => {
                state.findCompanyStatus = 'failed'
            })
            .addCase(findAllAtecos.pending, (state) => {
                state.findAllAtecosStatus = 'loading'
            })
            .addCase(findAllAtecos.fulfilled, (state, action) => {
                state.findAllAtecosStatus = 'successfully'
                state.findAllAtecosResponse = action.payload
            })
            .addCase(findAllAtecos.rejected, (state) => {
                state.findAllAtecosStatus = 'failed'
            })
            .addCase(findCompanyActivityCategories.pending, (state) => {
                state.findCompanyCategoryStatus = 'loading'
            })
            .addCase(findCompanyActivityCategories.fulfilled, (state, action) => {
                state.findCompanyCategoryStatus = 'successfully'
                state.findCompanyCategoryResponse = action.payload
            })
            .addCase(findCompanyActivityCategories.rejected, (state) => {
                state.findCompanyCategoryStatus = 'failed'
            })
            .addCase(findCompanyActivityTypes.pending, (state) => {
                state.findCompanyTypesStatus = 'loading'
            })
            .addCase(findCompanyActivityTypes.fulfilled, (state, action) => {
                state.findCompanyTypesStatus = 'successfully'
                state.findCompanyTypesResponse = action.payload
            })
            .addCase(findCompanyActivityTypes.rejected, (state) => {
                state.findCompanyTypesStatus = 'failed'
            })
            .addCase(findAllReceivedDocuments.pending, (state) => {
                state.findAllReceivedDocumentsStatus = 'loading'
            })
            .addCase(findAllReceivedDocuments.fulfilled, (state, action) => {
                state.findAllReceivedDocumentsStatus = 'successfully'
                state.findAllRecivedDocumentsResponse = action.payload
            })
            .addCase(findAllReceivedDocuments.rejected, (state) => {
                state.findAllReceivedDocumentsStatus = 'failed'
            })
            .addCase(findAllSentDocuments.pending, (state) => {
                state.findAllSentDocumentsStatus = 'loading'
            })
            .addCase(findAllSentDocuments.fulfilled, (state, action) => {
                state.findAllSentDocumentsStatus = 'successfully'
                state.findAllSentDocumentsResponse = action.payload
            })
            .addCase(findAllSentDocuments.rejected, (state) => {
                state.findAllSentDocumentsStatus = 'failed'
            })
            .addCase(findDocumentById.pending, (state) => {
                state.findDocumentByIdStatus = 'loading'
            })
            .addCase(findDocumentById.fulfilled, (state, action) => {
                state.findDocumentByIdStatus = 'successfully'
                state.findDocumentByIdResponse = action.payload
            })
            .addCase(findDocumentById.rejected, (state) => {
                state.findDocumentByIdStatus = 'failed'
            })
            .addCase(deleteDocument.pending, (state) => {
                state.deleteDocumentStatus = 'loading'
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                state.deleteDocumentStatus = 'successfully'
            })
            .addCase(deleteDocument.rejected, (state) => {
                state.deleteDocumentStatus = 'failed'
            })
            .addCase(getCompanyUnseenInfoDocuments.pending, (state) => {
                state.companiesUnseenDocumentsStatus = 'loading'
            })
            .addCase(getCompanyUnseenInfoDocuments.fulfilled, (state, action) => {
                state.companiesUnseenDocumentsStatus = 'successfully'
                state.companiesUnseenDocumentsResponse = action.payload
            })
            .addCase(getCompanyUnseenInfoDocuments.rejected, (state) => {
                state.companiesUnseenDocumentsStatus = 'failed'
            })
    },
})

export const {
    setDocumentTabValue,
    setFindCompanyStatus,
    setOpenDocumentModal,
    setFindDocumentStatus,
    setDeleteDocumentOpenModal,
    setDocumentSentFilterPeriod,
    setDocumentSentFilterYear,
    setDocumentReceivedFilterPeriod,
    setDocumentReceivedFilterYear,
    setDeleteDocumentStatus,
    setDocumentReceivedFilterPage,
    setDocumentSentFilterPage,
    setFindAllReceivedDocumentsStatus,
} = companySlice.actions

export default companySlice.reducer