import axios from "axios";
import { keycloak } from "../keycloak";
import { EditEventDTO, EventsFilters, FindAllEventsDTO, NewEventDTO, UpdateEventStatusRequest } from "./dto";
import { EventsService } from "./service";

export class EventsServiceImpl implements EventsService {
    public createEvent(request: NewEventDTO): Promise<void> {
        let url: string = "/api/events"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findEvents(request: EventsFilters): Promise<FindAllEventsDTO> {
        let url: string = "/api/events"
        let params = {
            fromDate: request.fromDate,
            toDate: request.toDate,
            itemsPerPage: request.itemsPerPage,
            page: request.page,
            companyId: request.companyId
        }
        return axios({
            url: url,
            method: "GET",
            params: params,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteEvent(request: number): Promise<FindAllEventsDTO> {
        let url: string = "/api/events/" + request
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editEvent(id: number, data: EditEventDTO): Promise<FindAllEventsDTO> {
        let url: string = "/api/events/" + id
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public updateStatus(id: number, data: UpdateEventStatusRequest): Promise<void> {
        let url: string = "/api/events/" + id + "/updateStatus"
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
} 