import { EmptyList } from "../../emptyList/emptyList";
import { HeadingSize } from "../../heading/dto";
import { LayoutComponent } from "../../layout";
import { PaginationComponent } from "../../pagination";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { MenuItems } from "../../sidebar/dto";
import { setFiscalDocumentBalanceFilterPage } from "../slice";
import { BalanceList } from "./balanceList";


export function BalanceView() {
    const dispatch = useAppDispatch()

    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)

    return (
        <LayoutComponent
            headingLabel={"Bilanci e Dichiarativi"}
            headingButtons={[]}
            breadcrumbItems={["Bilanci e Dichiarativi"]}
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.BALANCE}
        >
            <div style={{ marginTop: '16px' }}>
                <BalanceList />
            </div>
            {
                fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentBalanceResponse.total > 0 &&
                <PaginationComponent
                    page={fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentBalanceResponse.page : 0}
                    count={fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentBalanceResponse.totalPage : 0}
                    onChange={(page) => dispatch(setFiscalDocumentBalanceFilterPage(page))}
                />
            }
            {
                fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentBalanceResponse.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}