import { differenceInDays, format } from "date-fns";
import { it } from "date-fns/locale";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { acubeReconnectRequest, editMe, findAcubeConnectRequest, findByIdAcubeGetAccount, setAcubeReconnectRequestStatus, setEditMeActivityNotification, setEditMeOperationsNotification, setEditMeStatus, setFindAcubeConnectRequestStatus } from "../auth/slice";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { ErrorPopup } from "../errorPopup";
import { FormGroup } from "../formGroup";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { LayoutComponent } from "../layout";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SuccessPopup } from "../successPopup";
import { SwitchComponent } from "../switch";
import { TextInput } from "../textInput";

export function ProfileView() {
    const authState = useAppSelector(state => state.auth)
    const documentsState = useAppSelector(state => state.document)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleCloseBankAccountMenu = () => {
        setAnchorEl(null);
    };

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    let atecoList: ReactNode[] = []
    let bankAccounts: ReactNode[] = []

    useEffect(() => {
        dispatch(findByIdAcubeGetAccount({ id: authState.findMeResponse !== undefined ? authState.findMeResponse?.id.toString() : '', uuid: authState.findMeResponse !== undefined ? authState.findMeResponse?.acubeAccounts : [] }))
    }, [])

    useEffect(() => {
        if (authState.findAcubeConnectRequestStatus === 'successfully' && authState.findAcubeConnectRequestResponse !== undefined) {
            const link = document.createElement('a');
            link.href = authState.findAcubeConnectRequestResponse
            link.rel = "noreferrer"
            link.target = "_blank"
            link.click();
            dispatch(setAcubeReconnectRequestStatus('idle'))
        }
    }, [authState.findAcubeConnectRequestStatus])

    useEffect(() => {
        if (authState.acubeReconnectRequestStatus === 'successfully' && authState.acubeReconnectRequestResponse !== undefined) {
            const link = document.createElement('a');
            link.href = authState.acubeReconnectRequestResponse.reconnectUrl
            link.rel = "noreferrer"
            link.target = "_blank"
            link.click();
            dispatch(setAcubeReconnectRequestStatus('idle'))
        }
    }, [authState.acubeReconnectRequestStatus])

    let acubeAccounts: ReactNode[] = []
    if (authState.findByIdAcubeGetAccountStatus === 'successfully' && authState.findByIdAcubeGetAccountResponse !== undefined &&
        authState.findByIdAcubeGetAccountResponse !== null &&
        authState.findByIdAcubeGetAccountResponse.length > 0) {
        authState.findByIdAcubeGetAccountResponse.forEach((bank, index) => {
            const expirationDate = new Date(bank.consentExpiresAt)
            const difference = differenceInDays(expirationDate, new Date())
            acubeAccounts.push(
                <div key={bank.uuid + '-' + bank.name} className="neutral-container--padding-small flex-row justify-content-between align-items-center" >
                    <span className="typography-neutral--400 text text--lg typography--semibold">
                        {bank.name + ' → '}
                        <span className={"typography--medium " + (difference <= 20 ? 'typography-destructive--400' : 'typography-neutral--400')}>
                            {(difference < 0 ? 'Scaduto' : 'Scade il ' + format(expirationDate, 'dd MMMM yyyy', { locale: it }))}
                        </span>
                    </span>
                    {
                        difference <= 20 &&
                        <ButtonComponent
                            label={"Rinnova consenso"}
                            onClick={() => dispatch(acubeReconnectRequest({ id: authState.findMeResponse !== undefined ? authState.findMeResponse?.id.toString() : '', uuid: bank.uuid }))}
                            color={Colors.PRIMARY}
                            variant={Variant.SOLID}
                            size={Size.SM}
                            iconStyle={IconStyle.OFF}
                        />
                    }
                </div>
            )
        })
    }

    if (
        authState.findMeResponse !== undefined &&
        authState.findMeResponse.atecoCodeIds !== null &&
        authState.findMeResponse.atecoCodeIds !== undefined &&
        authState.findMeResponse.atecoCodeIds.length > 0
    ) {
        authState.findMeResponse.atecoCodeIds.forEach(id => {
            const ateco = documentsState.findAllAtecosResponse?.data.find(_ateco => _ateco.id.toString() === id.toString())
            if (ateco !== undefined) {
                atecoList.push(
                    <PillComponent key={ateco.id} label={ateco.code} size={PillsSize.MD} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} />
                )
            }
        })
    }

    if (
        authState.findMeResponse !== undefined &&
        authState.findMeResponse.bankingAccounts !== null &&
        authState.findMeResponse.bankingAccounts !== undefined &&
        authState.findMeResponse.bankingAccounts.length > 0
    ) {
        authState.findMeResponse.bankingAccounts.forEach((bank, index) => {
            bankAccounts.push(
                <div key={bank} className="neutral-container--padding-small flex-row justify-content-between align-items-center" >
                    <span className="typography-neutral--400 text text--lg typography--semibold">
                        {bank.split('::')[0] + ' '}
                        <span className="typography--medium">
                            {'→ ' + bank.split('::')[1]}
                        </span>
                    </span>
                </div>
            )
        })
    }

    let formData = new FormData()

    return (
        <LayoutComponent
            headingLabel={"Impostazioni"}
            headingButtons={[]}
            breadcrumbItems={['Impostazioni']}
            showSpinner={authState.findByIdAcubeGetAccountStatus === 'loading'}
            headingSize={HeadingSize.LG}
        >
            <ErrorPopup
                active={authState.findAcubeConnectRequestStatus === 'failed'}
                close={() => dispatch(setFindAcubeConnectRequestStatus('idle'))}
                message="Si è verificato un errore nella richiesta"
            />
            <SuccessPopup
                active={authState.findAcubeConnectRequestStatus === 'successfully'}
                close={() => dispatch(setFindAcubeConnectRequestStatus('idle'))}
                message="Richiesta mandata con successo"
            />
            <ErrorPopup
                active={authState.editMeStatus === 'failed'}
                close={() => dispatch(setEditMeStatus('idle'))}
                message="Si è verificato un errore durante la modifica della gestione delle notifiche."
            />
            <SuccessPopup
                active={authState.editMeStatus === 'successfully'}
                close={() => dispatch(setEditMeStatus('idle'))}
                message="Gestione notifiche aggiornata."
            />
            <div className="row m-0 p-0" style={{ gap: '24px' }}>
                <div className="col-12 col-lg m-0 p-0">
                    <div className="neutral-container neutral-container--padding-medium">
                        <HeadingComponent
                            label={"Anagrafica azienda"}
                            buttons={[]}
                            size={HeadingSize.MD}
                        />
                        <div className="row m-0 p-0 w-100" style={{ gap: '16px' }}>
                            <div className="col m-0 p-0">
                                <FormGroup label={"Ragione sociale"}
                                    inputs={[
                                        <TextInput id={"profile-edit-business-name"} disabled value={authState.findMeResponse?.businessName !== null ? authState.findMeResponse?.businessName : ''} key={"profile-edit-name"} type={"text"} placeholder={"Ragione sociale"} label="Ragione sociale" />
                                    ]}
                                    style={"column"}
                                />
                            </div>
                            <div className="col m-0 p-0">
                                <FormGroup label={"Numero dipendenti"}
                                    inputs={[
                                        <TextInput
                                            id={"profile-edit-employees"}
                                            disabled
                                            defaultValue={authState.findMeResponse?.employeesNumber !== null ? authState.findMeResponse?.employeesNumber.toString() : ''}
                                            key={"profile-edit-employees"}
                                            type={"text"}
                                            placeholder={"Numero dipendenti"}
                                        />
                                    ]}
                                    style={"column"}
                                />
                            </div>
                        </div>
                        <div className="row m-0 p-0 w-100" style={{ gap: '16px' }}>
                            <div className="col m-0 p-0">
                                <FormGroup label={"Nome"}
                                    inputs={[
                                        <TextInput
                                            disabled
                                            id={"profile-edit-name"}
                                            defaultValue={authState.findMeResponse?.name !== null ? authState.findMeResponse?.name : ''}
                                            key={"profile-edit-name"}
                                            type={"text"}
                                            placeholder={"Nome"}
                                        />
                                    ]}
                                    style={"column"}
                                />
                            </div>
                            <div className="col m-0 p-0">
                                <FormGroup label={"Cognome"}
                                    inputs={[
                                        <TextInput
                                            id={"profile-edit-surname"}
                                            disabled
                                            defaultValue={authState.findMeResponse?.surname !== null ? authState.findMeResponse?.surname : ''}
                                            key={"profile-edit-surname"}
                                            type={"text"}
                                            placeholder={"Cognome"}
                                        />
                                    ]}
                                    style={"column"}
                                />
                            </div>
                        </div>
                        <div className="row m-0 p-0 w-100" style={{ gap: '16px' }}>
                            <div className="col m-0 p-0">
                                <FormGroup label={"Email"}
                                    inputs={[
                                        <TextInput id={"profile-edit-email"} disabled value={authState.findMeResponse?.email !== null ? authState.findMeResponse?.email : ''} key={"profile-edit-email"} type={"text"} placeholder={"Email"} label="Ragione sociale" />
                                    ]}
                                    style={"column"}
                                />
                            </div>
                            <div className="col m-0 p-0">
                                <FormGroup label={"Telefono"}
                                    inputs={[
                                        <TextInput
                                            id={"profile-edit-phone-number"}
                                            disabled
                                            defaultValue={authState.findMeResponse?.phoneNumber !== null ? authState.findMeResponse?.phoneNumber : ''}
                                            key={"profile-edit-phone-number"}
                                            type={"text"}
                                            placeholder={"Telefono"}
                                        />
                                    ]}
                                    style={"column"}
                                />
                            </div>
                        </div>
                        <div className="row m-0 p-0 w-100" style={{ gap: '16' }}>
                            <div className="col m-0 p-0">
                                <FormGroup label={"Descrizione attività"}
                                    inputs={[
                                        <TextInput
                                            id={"profile-edit-notes"}
                                            disabled
                                            defaultValue={authState.findMeResponse?.note !== null ? authState.findMeResponse?.note : ''}
                                            key={"profile-edit-note"}
                                            multiline
                                            type={"text"}
                                            placeholder={"Un po' di informazioni sull'azienda e sui servizi che offre."}
                                        />
                                    ]}
                                    style={"column"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg m-0 p-0 d-flex flex-column" style={{ gap: '16px' }}>
                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <div className="primary-container" style={{ flexDirection: 'column', gap: '16px' }}>
                                <HeadingComponent
                                    label={"Accesso conto corrente"}
                                    buttons={[
                                        <ButtonComponent
                                            label={"Richiedi accesso"}
                                            onClick={() => dispatch(findAcubeConnectRequest(authState.findMeResponse !== undefined ? authState.findMeResponse?.id.toString() : ''))}
                                            color={Colors.PRIMARY}
                                            variant={Variant.OUTLINE}
                                            size={Size.MD}
                                            iconStyle={IconStyle.OFF}
                                        />
                                    ]}
                                    size={HeadingSize.MD}
                                />
                                <div style={{ maxHeight: '150px', overflow: 'auto', gap: '16px', display: 'flex', flexDirection: 'column' }}>
                                    {acubeAccounts}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <div className="neutral-container neutral-container--padding-medium" >
                                <HeadingComponent size={HeadingSize.SM} label={"Sezionali registri"} buttons={[]} />
                                {
                                    atecoList.length > 0 &&
                                    <div className="neutral-container--padding-small flex-row" >
                                        {atecoList}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <div className="neutral-container neutral-container--padding-medium">
                                <HeadingComponent size={HeadingSize.SM} label={"Numero estratti conto"} buttons={[]} />
                                <div style={{ overflow: 'auto', gap: '16px', display: 'flex', flexDirection: 'column', width: '100%', paddingRight: '16px', height: '101px' }}>
                                    {bankAccounts}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 p-0" style={{ flexGrow: 1 }}>
                        <div className="col-12 m-0 p-0">
                            <div className="neutral-container neutral-container--padding-medium h-100">
                                <HeadingComponent size={HeadingSize.SM} label={"Gestione Notifiche"} buttons={[
                                    <ButtonComponent
                                        label={"Aggiorna"}
                                        onClick={e => {
                                            dispatch(editMe({ data: authState.editMeRequest, id: authState.findMeResponse?.id.toString() || '' }))
                                        }}
                                        color={Colors.PRIMARY}
                                        variant={Variant.OUTLINE}
                                        size={Size.MD}
                                        iconStyle={IconStyle.OFF}
                                    />
                                ]} />
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexGrow: 1, gap: '40px', flexWrap: 'wrap' }}>
                                    <SwitchComponent
                                        label="Attività"
                                        id={"edit-me-activity-notifications"}
                                        onChange={(_, checked) => dispatch(setEditMeActivityNotification(checked))}
                                        checked={authState.editMeRequest.activityNotifications === undefined ? authState.findMeResponse?.activityNotifications : authState.editMeRequest.activityNotifications}
                                    />
                                    <SwitchComponent
                                        label="Operazioni (messaggi, documenti, conto corrente)"
                                        id={"edit-me-document-notifications"}
                                        onChange={(_, checked) => dispatch(setEditMeOperationsNotification(checked))}
                                        checked={authState.editMeRequest.operationsNotifications === undefined ? authState.findMeResponse?.operationsNotifications : authState.editMeRequest.operationsNotifications}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    )
}