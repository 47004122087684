import { useEffect, useState } from "react"
import colors from '../utils/index.module.scss'
import { IconsProps } from "./dto"

export function BalanceAlertIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: colors.primary500, position: 'absolute', top: '2px', left: '13px' }} />
            <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
                onMouseOut={() => setColor(props.colorBase)}
                width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1133_3165)">
                    <path d="M17.6751 13.2416C17.1449 14.4954 16.3157 15.6001 15.2599 16.4594C14.2042 17.3186 12.954 17.9061 11.6187 18.1706C10.2835 18.4351 8.90374 18.3684 7.60017 17.9765C6.29661 17.5845 5.10891 16.8792 4.1409 15.9222C3.1729 14.9652 2.45406 13.7856 2.04725 12.4866C1.64043 11.1876 1.55802 9.8087 1.80722 8.47049C2.05641 7.13228 2.62963 5.87548 3.47676 4.80998C4.32388 3.74449 5.41912 2.90272 6.66672 2.35829M18.3334 9.99996C18.3334 8.90561 18.1178 7.82198 17.699 6.81093C17.2803 5.79988 16.6664 4.88122 15.8926 4.1074C15.1188 3.33358 14.2001 2.71975 13.1891 2.30096C12.178 1.88217 11.0944 1.66663 10.0001 1.66663V9.99996H18.3334Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_1133_3165">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}