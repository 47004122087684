import { EmptyList } from "../../emptyList/emptyList";
import { HeadingSize } from "../../heading/dto";
import { LayoutComponent } from "../../layout";
import { PaginationComponent } from "../../pagination";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { MenuItems } from "../../sidebar/dto";
import { setFiscalDocumentEmployeeFilterPage } from "../slice";
import { EmployeeList } from "./employeeList";


export function EmployeeView() {
    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)

    const dispatch = useAppDispatch()
    
    return (
        <LayoutComponent
            headingLabel={"Gestione dipendenti"}
            headingButtons={[]}
            breadcrumbItems={["Gestione dipendenti"]}
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.EMPLOYEE}
        >
            <div style={{ marginTop: '16px' }}>
                <EmployeeList />
            </div>
            {
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.total > 0 &&
                <PaginationComponent
                    page={fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.page : 0}
                    count={fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.totalPage : 0}
                    onChange={(page) => dispatch(setFiscalDocumentEmployeeFilterPage(page))}
                />
            }
            {
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}