import { EmptyList } from "../../emptyList/emptyList";
import { HeadingSize } from "../../heading/dto";
import { LayoutComponent } from "../../layout";
import { PaginationComponent } from "../../pagination";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { MenuItems } from "../../sidebar/dto";
import { setFiscalDocumentOthersFilterPage } from "../slice";
import { OthersList } from "./other";


export function OthersView() {
    const dispatch = useAppDispatch()

    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)

    return (
        <LayoutComponent
            headingLabel={"Altro"}
            headingButtons={[]}
            breadcrumbItems={["Altro"]}
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.OTHER}
        >
            <div style={{ marginTop: '16px' }}>
                <OthersList />
            </div>
            {
                fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentOthersResponse.total > 0 &&
                <PaginationComponent
                    page={fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentOthersResponse.page : 0}
                    count={fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentOthersResponse.totalPage : 0}
                    onChange={(page) => dispatch(setFiscalDocumentOthersFilterPage(page))}
                />
            }
            {
                fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentOthersResponse.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}