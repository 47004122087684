import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { } from 'date-fns';
import it from 'date-fns/locale/it';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import colors from '../utils/index.module.scss';

interface Props {
    onMonthChange?: ((month: Date) => void)
    onChange?: ((date: Date) => void)
}

function ServerDay(props: PickersDayProps<Date> & { highlightedDays?: number[] }) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
        !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.getDate()) >= 0;

    return (
        <PickersDay
            sx={{
                backgroundColor: (isSelected ? colors.primary500 : 'transparent'),
                color: (isSelected ? colors.white : (day.getDay() === 0 ? colors.destructive500 : colors.neutral500)) + ' !important',
                ' :hover': {
                    color: colors.neutral500 + ' !important'
                }
            }}
            {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    );
}


export function EventsCalendarComponent(props: Props) {
    const { onMonthChange } = props
    const { onChange } = props

    const eventsState = useAppSelector(state => state.events)

    const dispatch = useAppDispatch()

    const dayMapping = new Map<string, string>([
        ['lun', 'L'],
        ['mar', 'M'],
        ['mer', 'M'],
        ['gio', 'G'],
        ['ven', 'V'],
        ['sab', 'S'],
        ['dom', 'D']
    ]);

    let highlightedDays: number[] = []

    if (
        eventsState.findEventsResponse !== undefined
    ) {
        highlightedDays = eventsState.findEventsResponse.data.map(event => new Date(event.date).getDate())
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
            <DateCalendar
                slots={{
                    day: ServerDay,
                }}
                slotProps={{
                    day: {
                        highlightedDays
                    } as any,
                }}
                views={['day']}
                onMonthChange={(date: Date) => onMonthChange && onMonthChange(date)}
                onChange={(date) => {
                    if (date !== null)
                        onChange && onChange(date)
                }}
                dayOfWeekFormatter={(day) => dayMapping.get(day) || ''}
                sx={{
                    display: 'flex',
                    width: '600px !important',
                    flexDirection: 'column',
                    gap: '16px !important',
                    height: '424px !important',
                    minHeight: '424px !important',
                    overflow: 'hidden !important',
                    '& .MuiPickersCalendarHeader-label': {
                        fontFamily: 'Figtree !important',
                        color: colors.primary800 + ' !important',
                        fontWeight: 700 + ' !important',
                        fontSize: '20px !important',
                        lineHeight: '28px !important',
                        verticalAlign: 'middle !important',
                        textTransform: 'capitalize !important',
                        margin: 0 + ' !important'
                    },
                    '& .MuiPickersCalendarHeader-labelContainer': {
                        margin: 0 + ' !important',
                    },
                    '& .MuiPickersCalendarHeader-root': {
                        margin: 0 + ' !important',
                        padding: 0 + ' !important',
                        display: 'flex !important',
                        justifyContent: 'space-between !important',
                        alignItems: 'center !important',
                    },
                    '.MuiDayCalendar-weekDayLabel': {
                        fontFamily: 'Figtree !important',
                        fontSize: '14px !important',
                        lineHeight: '16px !important',
                        fontWeight: 500 + ' !important',
                        color: colors.neutral200,
                        height: '24px !important',
                        width: '40px !important',
                        margin: 0 + ' !important',
                    },
                    '.MuiDayCalendar-weekDayLabel:last-of-type': {
                        fontFamily: 'Figtree !important',
                        fontSize: '14px !important',
                        lineHeight: '16px !important',
                        fontWeight: 500 + ' !important',
                        color: colors.destructive500 + ' !important',
                        height: '24px !important',
                        width: '40px !important'
                    },
                    '.MuiDayCalendar-root': {
                        gap: '16px !important',
                        display: 'flex !important',
                        flexDirection: 'column !important',
                        height: 'auto !important'
                    },
                    '.MuiPickersDay-root': {
                        fontFamily: 'Figtree !important',
                        fontSize: '14px !important',
                        lineHeight: '20px !important',
                        fontWeight: 500 + ' !important',
                        color: colors.neutral700,
                        height: '40px !important',
                        width: '40px !important',
                        borderRadius: '50% !important',
                        margin: 0 + ' !important'
                    },
                    '.MuiPickersDay-root[aria-colindex="7"]': {
                        fontFamily: 'Figtree !important',
                        fontSize: '14px !important',
                        lineHeight: '20px !important',
                        fontWeight: 500 + ' !important',
                        color: colors.destructive500,
                        height: '40px !important',
                        width: '40px !important',
                        borderRadius: '50% !important',
                        margin: 0 + ' !important'
                    },
                    '.MuiPickersDay-today': {
                        height: '40px !important',
                        width: '40px !important',
                        borderRadius: '50% !important',
                        border: '1px solid ' + colors.primary500 + ' !important',
                        color: colors.neutral500
                    },
                    '.Mui-selected': {
                        height: '40px !important',
                        width: '40px !important',
                        color: colors.white + ' !important',
                        backgroundColor: colors.primary500 + ' !important'
                    },
                    ' .MuiDayCalendar-monthContainer': {
                        width: '100%',
                        flexGrow: 1,
                        gap: '16px !important',
                        display: 'flex !important',
                        flexDirection: 'column !important',
                    },
                    '.MuiDayCalendar-header': {
                        width: '600px !important',
                        justifyContent: 'space-between !important',
                        gap: 'auto !important',
                    },
                    '& .MuiPickersSlideTransition-root': {
                        overflow: 'hidden !important',
                        height: '320px !important'
                    },
                    '& .MuiDayCalendar-weekContainer': {
                        width: '100% !important',
                        justifyContent: 'space-between !important',
                        margin: 0 + ' !important',
                        height: '40px !important'
                    },
                    '& .MuiPickersSlideTransition-root div': {
                        height: '100% !important'
                    },
                    ' .MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected': {
                        backgroundColor: colors.primary500 + ' !important'
                    }
                }}
            />
        </LocalizationProvider>
    );
}