import { useEffect, useState } from "react"
import { IconsProps } from "./dto"

export function DocumentReceivedIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66664 1.04167C5.05885 1.04167 4.47595 1.28311 4.04618 1.71288C3.61641 2.14266 3.37497 2.72555 3.37497 3.33334V16.6667C3.37497 17.2745 3.61641 17.8574 4.04618 18.2872C4.47595 18.7169 5.05885 18.9584 5.66664 18.9584H15.6667C16.2745 18.9584 16.8573 18.7169 17.2871 18.2872C17.7169 17.8574 17.9583 17.2745 17.9583 16.6667V7.50002C17.9583 7.33426 17.8925 7.17529 17.7753 7.05808L11.9419 1.22473C11.8247 1.10752 11.6657 1.04167 11.5 1.04167H5.66664ZM4.93007 2.59677C5.12542 2.40142 5.39037 2.29167 5.66664 2.29167H10.875V7.50002C10.875 7.8452 11.1548 8.12502 11.5 8.12502H16.7083V16.6667C16.7083 16.943 16.5986 17.2079 16.4032 17.4033C16.2079 17.5986 15.9429 17.7084 15.6667 17.7084H5.66664C5.39037 17.7084 5.12542 17.5986 4.93007 17.4033C4.73472 17.2079 4.62497 16.943 4.62497 16.6667V3.33334C4.62497 3.05707 4.73472 2.79212 4.93007 2.59677ZM15.8244 6.87502L12.125 3.17556V6.87502H15.8244ZM11.2917 9.16669C11.2917 8.82151 11.0118 8.54169 10.6667 8.54169C10.3215 8.54169 10.0416 8.82151 10.0416 9.16669V14.3245L7.77525 12.0581C7.53117 11.814 7.13545 11.814 6.89137 12.0581C6.64729 12.3022 6.64729 12.6979 6.89137 12.942L10.2247 16.2753C10.4688 16.5194 10.8645 16.5194 11.1086 16.2753L14.4419 12.942C14.686 12.6979 14.686 12.3022 14.4419 12.0581C14.1979 11.814 13.8021 11.814 13.5581 12.0581L11.2917 14.3245V9.16669Z" fill={color} />
        </svg>
    )
}
