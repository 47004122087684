import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ActivityDTO } from "../documents/dto"
import { PromiseStatuses } from "../utils"
import { ActivityTypeCreationDTO, ActivityTypeFilters, FindAllActivityCategory, FindAllActivityType } from "./dto"
import { NewActivityService } from "./service"

interface ActivityState {
    activityTypeFilters: ActivityTypeFilters
    findAllActivityCategoryResponse?: FindAllActivityCategory
    findAllActivityTypeResponse?: FindAllActivityType
    createActivityTypeRequest: ActivityTypeCreationDTO
    findAllActivityCategoryStatus: PromiseStatuses
    findAllActivityTypeStatus: PromiseStatuses
    createActivityTypeStatus: PromiseStatuses
    newActivityModalOpen: boolean
    findReceivedDocsActivitiesResponse: ActivityDTO[]
    findReceivedDocsActivitiesStatus: PromiseStatuses
    findSentDocsActivitiesResponse: ActivityDTO[]
    findSentDocsActivitiesStatus: PromiseStatuses
}

const initialState: ActivityState = {
    findAllActivityCategoryStatus: 'idle',
    findAllActivityTypeStatus: 'idle',
    createActivityTypeStatus: 'idle',
    createActivityTypeRequest: {
        name: '',
        activityCategoryId: 0
    },
    newActivityModalOpen: false,
    activityTypeFilters: {
        name: '',
        activityCategoryId: ''
    },
    findReceivedDocsActivitiesResponse: [],
    findReceivedDocsActivitiesStatus: 'idle',
    findSentDocsActivitiesResponse: [],
    findSentDocsActivitiesStatus: 'idle'
}

export const findAllActivityCategory = createAsyncThunk(
    'activity/findAllActivityCategory',
    async (_, thunkApi): Promise<FindAllActivityCategory> => {
        const activityService = NewActivityService()

        return activityService.findAllActivityCategory().catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAllActivityType = createAsyncThunk(
    'activity/findAllActivityType',
    async (request: ActivityTypeFilters, thunkApi): Promise<FindAllActivityType> => {
        const activityService = NewActivityService()

        return activityService.findAllActivityType(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const createActivityType = createAsyncThunk(
    'activity/createActivityType',
    async (activityTypeCreationDTO: ActivityTypeCreationDTO, thunkApi): Promise<void> => {
        const activityService = NewActivityService()

        return activityService.createActivityType(activityTypeCreationDTO).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findReceivedDocsActivity = createAsyncThunk(
    'company/findReceivedDocsActivity',
    async (request: number[], thunkApi): Promise<ActivityDTO[]> => {
        let promises: Promise<ActivityDTO>[] = []
        promises = request.map(async (id) => {
            const activityService = NewActivityService()

            return activityService.findActivity(id)
        })
        return Promise.all(promises)
    }
)

export const findSentDocsActivity = createAsyncThunk(
    'company/findSentDocsActivity',
    async (request: number[], thunkApi): Promise<ActivityDTO[]> => {
        let promises: Promise<ActivityDTO>[] = []
        promises = request.map(async (id) => {
            const activityService = NewActivityService()

            return activityService.findActivity(id)
        })
        return Promise.all(promises)
    }
)

const activitySlice = createSlice({
    name: 'activity/slice',
    initialState,
    reducers: {
        setActivityFilter: (state, action) => {
            state.activityTypeFilters.name = action.payload
        },
        setTypeFilter: (state, action) => {
            state.activityTypeFilters.activityCategoryId = action.payload
        },
        setNewActivityModalOpen: (state, action) => {
            state.newActivityModalOpen = action.payload
        },
        setNewActivityTypeName: (state, action) => {
            state.createActivityTypeRequest.name = action.payload
        },
        setNewActivityTypeActivityCategoryId: (state, action) => {
            state.createActivityTypeRequest.activityCategoryId = action.payload
        },
        resetNewActivityTypeRequest: (state) => {
            state.createActivityTypeRequest = {
                name: '',
                activityCategoryId: 0
            }
        },
        setCreateActivityTypeState: (state, action) => {
            state.createActivityTypeStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findAllActivityCategory.pending, (state) => {
                state.findAllActivityCategoryStatus = 'loading'
            })
            .addCase(findAllActivityCategory.fulfilled, (state, action) => {
                state.findAllActivityCategoryStatus = 'successfully'
                state.findAllActivityCategoryResponse = action.payload
            })
            .addCase(findAllActivityCategory.rejected, (state) => {
                state.findAllActivityCategoryStatus = 'failed'
            })
            .addCase(findAllActivityType.pending, (state) => {
                state.findAllActivityTypeStatus = 'loading'
            })
            .addCase(findAllActivityType.fulfilled, (state, action) => {
                state.findAllActivityTypeStatus = 'successfully'
                state.findAllActivityTypeResponse = action.payload
            })
            .addCase(findAllActivityType.rejected, (state) => {
                state.findAllActivityTypeStatus = 'failed'
            })
            .addCase(createActivityType.pending, (state) => {
                state.createActivityTypeStatus = 'loading'
            })
            .addCase(createActivityType.fulfilled, (state) => {
                state.createActivityTypeStatus = 'successfully'
            })
            .addCase(createActivityType.rejected, (state) => {
                state.createActivityTypeStatus = 'failed'
            })
            .addCase(findReceivedDocsActivity.pending, (state) => {
                state.findReceivedDocsActivitiesStatus = 'loading'
            })
            .addCase(findReceivedDocsActivity.fulfilled, (state, action) => {
                state.findReceivedDocsActivitiesStatus = 'successfully'
                state.findReceivedDocsActivitiesResponse = action.payload
            })
            .addCase(findReceivedDocsActivity.rejected, (state) => {
                state.findReceivedDocsActivitiesStatus = 'failed'
            })
            .addCase(findSentDocsActivity.pending, (state) => {
                state.findSentDocsActivitiesStatus = 'loading'
            })
            .addCase(findSentDocsActivity.fulfilled, (state, action) => {
                state.findSentDocsActivitiesStatus = 'successfully'
                state.findSentDocsActivitiesResponse = action.payload
            })
            .addCase(findSentDocsActivity.rejected, (state) => {
                state.findSentDocsActivitiesStatus = 'failed'
            })
    },
})

export const {
    setActivityFilter,
    setTypeFilter,
    setNewActivityModalOpen,
    setNewActivityTypeActivityCategoryId,
    setNewActivityTypeName,
    resetNewActivityTypeRequest,
    setCreateActivityTypeState
} = activitySlice.actions

export default activitySlice.reducer