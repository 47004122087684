import { TableCell } from "@mui/material";
import { ReactNode } from "react";

interface Props {
    label?: string
    content?: ReactNode
    cellType: 'head' | 'row-bold' | 'row-medium' | 'row-regular' | 'row-semibold'
    alignLabel: 'left' | 'center' | 'right'
    size?: 'large' | 'medium' | 'small' | 'context-menu'
}

export function TableCellComponent(props: Props) {
    const { label } = props
    const { content } = props
    const { cellType } = props
    const { alignLabel } = props
    const { size } = props

    const cellTypeFontWeightMap = new Map<'row-bold' | 'row-medium' | 'row-regular' | 'row-semibold', string>([
        ['row-bold', 'typography--bold'],
        ['row-medium', 'typography--medium'],
        ['row-regular', 'typography--regular'],
        ['row-semibold', 'typography--semibold']
    ])

    const cellSizeMap = new Map<'large' | 'medium' | 'small' | 'context-menu' | undefined, string>([
        ['large', '20%'],
        ['medium', 'auto'],
        ['small', '100px'],
        ['context-menu', '24px'],
        [undefined, 'auto'],
    ])

    return (
        <TableCell
            width={cellSizeMap.get(size)}
            align={alignLabel} sx={{ padding: '16px 8px', minHeight: '10px !important' }}>
            <span
                className={cellType === 'head' ?
                    (
                        'overline overline--xs typography--medium typography-neutral--400 text-overflow'
                    ) : (
                        'text text--md typograpy-neutral--600 text-overflow ' + cellTypeFontWeightMap.get(cellType)
                    )}
            >
                {cellType === 'head' && label ? label.toUpperCase() : label}
            </span>
            {content}
        </TableCell>
    )
}