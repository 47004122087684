import { ActivityDTO } from "../documents/dto";
import { ActivityCategory, ActivityTypeCreationDTO, ActivityTypeFilters, FindAllActivityCategory, FindAllActivityType } from "./dto";
import { ActivityServiceImpl } from "./serviceImpl";

export interface ActivityService {
    findAllActivityCategory(): Promise<FindAllActivityCategory>
    findAllActivityType(filters: ActivityTypeFilters): Promise<FindAllActivityType>
    createActivityType(data: ActivityTypeCreationDTO): Promise<void>
    findActivityCategory(id: number): Promise<ActivityCategory>
    findActivity(id: number): Promise<ActivityDTO>
}

export function NewActivityService(): ActivityService {
    return new ActivityServiceImpl();
}
