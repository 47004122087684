import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { FileInputComponent } from "../fileInput";
import { FormGroup } from "../formGroup";
import { TrashIcon } from "../icons/trash";
import { ModalComponent } from "../modal";
import { saveDocument } from "../objects/slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { TextInput } from "../textInput";
import colors from '../utils/index.module.scss';
import { DocumentCreationDTO, PeriodicityEnum } from "./dto";
import { setOpenDocumentModal } from "./slice";

export function AccountingAreaDocumentsModal() {
    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.document)
    const authState = useAppSelector(state => state.auth)
    const activityState = useAppSelector(state => state.activity)

    const [file, setFile] = useState<(File)[]>([])
    const [name, setName] = useState<string[]>([])
    const [formDatas, setFormDatas] = useState<FormData[]>([])

    const [type, setType] = useState<string>('contabile')
    const [activityTypeId, setActivityTypeId] = useState<string>('')
    const [date, setDate] = useState<string>('2024-07-17')
    const [month, setMonth] = useState<string>('')
    const [year, setYear] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    useEffect(() => {
        if (companyState.openSendDocumentModal) {
            setFile([])
            setName([])
            setFormDatas([])
            setActivityTypeId('')
            setMonth('')
            setYear('')
        }
    }, [companyState.openSendDocumentModal])

    let activities: ReactNode[] = []
    let years: ReactNode[] = []
    const startYear = 2022

    for (let i = startYear; i <= (new Date().getFullYear() + 1); i++) {
        years.push(<MenuItem value={i} key={i}>{i}</MenuItem>)
    }

    if (
        activityState.findAllActivityTypeStatus === 'successfully' &&
        activityState.findAllActivityTypeResponse !== undefined &&
        activityState.findAllActivityTypeResponse.data !== undefined &&
        activityState.findAllActivityTypeResponse.data.length > 0
    ) {
        activityState.findAllActivityTypeResponse.data
            .filter(activity => authState.findMeResponse?.activityTypeIds.includes(activity.id))
            .forEach((activityType) => {
                activities.push(
                    <MenuItem value={activityType.id} key={activityType.id}>{activityType.name}</MenuItem>
                )
            })
    }

    return (
        <ModalComponent
            wide
            open={companyState.openSendDocumentModal}
            handleClose={() => dispatch(setOpenDocumentModal(false))}
        >
            <div
                style={{
                    padding: '30px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                <div className="d-flex flex-row align-items-start justify-content-between" style={{ gap: '32px', width: '100%', maxHeight: '60vh' }}>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', maxHeight: '57vh', gap: 8 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 8, width: '100%', alignItems: 'flex-start' }}>
                            <FormGroup
                                label="Aggiungi file"
                                required
                                inputs={[
                                    <FileInputComponent
                                        multiple
                                        id={"accounting-area-documents-modal-input"}
                                        onChange={e => {
                                            if (e !== null && e !== undefined) {
                                                const newFiles = [...file]
                                                setFile([...newFiles, ...e])
                                                const newNames = [...name]
                                                setName([...newNames, ...e.map(file => file.name)])
                                                const newformDatas = [...formDatas]
                                                let formData: FormData[] = []
                                                e.forEach(_ => formData.push(new FormData()))
                                                setFormDatas([...newformDatas, ...formData])
                                            }
                                        }}
                                    />
                                ]}
                                style={"column"}
                            />
                        </div>
                        <div style={{ overflow: 'auto', width: '100%', maxHeight: '100%' }}>
                            {
                                file.map((_, index) => (
                                    <div style={{ width: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }} key={index}>
                                        <FormGroup
                                            label=""
                                            required
                                            inputs={[
                                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
                                                    <TextInput key={"accounting-area-documents-modal-input-name"}
                                                        onChange={e => {
                                                            const newNames = [...name]
                                                            newNames[index] = e.target.value
                                                            setName([...newNames])
                                                        }}
                                                        id={"type"}
                                                        value={name[index]}
                                                        type={"text"}
                                                        placeholder={"es. Fatture Acquisti Marzo 2023"}
                                                    />
                                                    <ButtonComponent
                                                        label={""}
                                                        onClick={() => {
                                                            const newFiles = [...file]
                                                            const newNames = [...name]
                                                            const newformDatas = [...formDatas]
                                                            setFile(newFiles.filter((_, _index) => _index !== index))
                                                            setName(newNames.filter((_, _index) => _index !== index))
                                                            setFormDatas(newformDatas.filter((_, _index) => _index !== index))
                                                        }}
                                                        color={Colors.PRIMARY}
                                                        variant={Variant.GHOST}
                                                        size={Size.SM}
                                                        iconStyle={IconStyle.ONLY}
                                                        icon={<TrashIcon colorBase={""} />}
                                                    />
                                                </div>
                                            ]}
                                            style={"column"}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 24, width: '47%', overflow: 'auto', maxHeight: '57vh', overflowX: 'hidden' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                            <FormGroup
                                label={"Tipologia"}
                                required
                                inputs={[
                                    <SelectComponent
                                        id={""}
                                        onChange={e => {
                                            setActivityTypeId(e.target.value)
                                        }}
                                        key={"accounting-area-documents-modal-input-type"}
                                        value={activityTypeId}
                                        menuItems={[<MenuItem value='' key={'0'}>Seleziona la tipologia...</MenuItem>, activities]}
                                    />]}
                                style={"column"}
                            />
                            <FormGroup
                                label={''}
                                inputs={[
                                    <div style={{ display: 'flex', gap: 12 }}>
                                        <SelectComponent
                                            id={""}
                                            required
                                            label="Anno di riferimento"
                                            onChange={e => {
                                                setYear(e.target.value)
                                            }}
                                            key={"accounting-area-documents-modal-input-year"}
                                            value={year}
                                            menuItems={[<MenuItem value='' key={'0'}>Seleziona anno</MenuItem>, years]}
                                        />
                                        <SelectComponent
                                            id={""}
                                            required
                                            label="Mese di riferimento"
                                            onChange={e => {
                                                setMonth(e.target.value)
                                            }}
                                            key={"accounting-area-documents-modal-input-month"}
                                            value={month}
                                            menuItems={
                                                authState.findMeResponse?.periodicity === PeriodicityEnum.Monthly ?
                                                    [
                                                        <MenuItem value='' key={'select-a-month'}>Seleziona mese</MenuItem>,
                                                        <MenuItem value='0' key={'0'}>Gennaio</MenuItem>,
                                                        <MenuItem value='1' key={'1'}>Febbraio</MenuItem>,
                                                        <MenuItem value='2' key={'2'}>Marzo</MenuItem>,
                                                        <MenuItem value='3' key={'3'}>Aprile</MenuItem>,
                                                        <MenuItem value='4' key={'4'}>Maggio</MenuItem>,
                                                        <MenuItem value='5' key={'5'}>Giugno</MenuItem>,
                                                        <MenuItem value='6' key={'6'}>Luglio</MenuItem>,
                                                        <MenuItem value='7' key={'7'}>Agosto</MenuItem>,
                                                        <MenuItem value='8' key={'8'}>Settembre</MenuItem>,
                                                        <MenuItem value='9' key={'9'}>Ottobre</MenuItem>,
                                                        <MenuItem value='10' key={'10'}>Novembre</MenuItem>,
                                                        <MenuItem value='11' key={'11'}>Dicembre</MenuItem>,
                                                    ] :
                                                    [
                                                        <MenuItem value='' key={'select-a-month'}>Seleziona trimestre</MenuItem>,
                                                        <MenuItem value='0' key={'0'}>Primo trimestre</MenuItem>,
                                                        <MenuItem value='1' key={'1'}>Secondo trimestre</MenuItem>,
                                                        <MenuItem value='2' key={'2'}>Terzo trimestre</MenuItem>,
                                                        <MenuItem value='3' key={'3'}>Quarto trimestre</MenuItem>
                                                    ]
                                            }
                                        />
                                    </div>
                                ]}
                                style={"column"}
                            />
                            <FormGroup
                                label={"Note (max 40 caratteri)"}
                                inputs={[<TextInput key={"accounting-area-documents-modal-input-notes"}
                                    onChange={e => {
                                        setDescription(e.target.value)
                                    }}
                                    id={"type"}
                                    max={40}
                                    type={"text"}
                                    placeholder={"Aggiungi qui una descrizione"}
                                    multiline
                                />]}
                                style={"column"} />
                        </div>
                        <div style={{ margin: 0, padding: 0, gap: '12px', justifySelf: 'flex-end' }} className="row">
                            <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Annulla"} onClick={() => dispatch(setOpenDocumentModal(false))} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} /></div>
                            <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Avvia procedura"}
                                onClick={() => {
                                    formDatas.forEach(form => form.delete('file'))
                                    formDatas.forEach(form => form.delete('document'))
                                    if (file.length > 0) {
                                        formDatas.forEach((form, index) => {
                                            if (file[index] !== null) {
                                                //@ts-ignore
                                                form.append('file', file[index])
                                            }
                                        });
                                    }
                                    const documentCreationDTO: DocumentCreationDTO = {
                                        expirationDate: date,
                                        note: description,
                                        fileName: 'name',
                                        type: type,
                                        period: month,
                                        year: year,
                                        activityTypeId: activityTypeId,
                                        senderGroup: 'companies',
                                        receiverGroup: ['accountants', 'operators'],
                                        companyId: authState.findMeResponse !== undefined ? authState.findMeResponse.id : 0,
                                    }
                                    dispatch(setOpenDocumentModal(false))
                                    formDatas.forEach((form, index) => form.append('document', JSON.stringify({ ...documentCreationDTO, fileName: name[index] })));
                                    dispatch(saveDocument(formDatas))
                                }}
                                color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.FIT} iconStyle={IconStyle.OFF}
                                disabled={
                                    file.some(f => f === null) ||
                                    file.some(f => f?.size !== undefined && f.size > (10 * 1024 * 1024)) ||
                                    name.some(n => n === '') ||
                                    activityTypeId === '' ||
                                    year === '' ||
                                    month === ''
                                }
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalComponent >
    )
}