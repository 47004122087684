import { EmptyList } from "../../emptyList/emptyList";
import { HeadingSize } from "../../heading/dto";
import { LayoutComponent } from "../../layout";
import { PaginationComponent } from "../../pagination";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { MenuItems } from "../../sidebar/dto";
import { setFiscalDocumentF24FilterPage } from "../slice";
import { F24List } from "./f24List";

export function F24View() {
    const dispatch = useAppDispatch()

    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)
    
    return (
        <LayoutComponent
            headingLabel={"F24"}
            headingButtons={[]}
            breadcrumbItems={["F24"]}
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.F24}
        >
            <div style={{ marginTop: '16px' }}>
                <F24List />
            </div>
            {
                fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentF24Response.total > 0 &&
                <PaginationComponent
                    page={fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined ? fiscalDocumentState.findAllFiscalDocumentF24Response.page : 0}
                    count={fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined ? fiscalDocumentState.findAllFiscalDocumentF24Response.totalPage : 0}
                    onChange={(page) => dispatch(setFiscalDocumentF24FilterPage(page))}
                />
            }
            {
                fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentF24Response.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}