import { CSSProperties, ReactElement, useState } from "react";
import colors from "../utils/index.module.scss";

interface Props {
    label: string,
    children: ReactElement
    position: 'bottom' | 'left' | 'bottom-left'
}

export function InfoTextPopup(props: Props) {
    const { label } = props
    const { children } = props
    const { position } = props

    const [hover, setHover] = useState<boolean>(false)

    const positionMap = new Map<'bottom' | 'left' | 'bottom-left', CSSProperties>([
        ['bottom', {
            position: "absolute",
            top: "115%",
            width: "auto",
            right: '0%',
            left: '0%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }],
        ['left', {
            position: "absolute",
            top: '50%',
            bottom: '50%',
            left: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto'
        }],
        ['bottom-left', {
            position: "absolute",
            top: "115%",
            width: "auto",
            right: '0%',
            left: '0%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        }]
    ])

    return (
        <div
            style={{
                position: "relative",
                width: '100%'
            }}
        >
            {
                hover &&
                <div
                    style={positionMap.get(position)}
                >
                    <div
                        className="text text--sm typography-neutral--400 text-center"
                        style={{
                            backgroundColor: colors.neutral20,
                            padding: '4px 6px',
                            borderRadius: '8px',
                            zIndex: 5,
                            boxShadow: colors.boxShadow,
                        }}>
                        {label}
                    </div>
                </div>
            }
            <div
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {children}
            </div>
        </div >
    )
}