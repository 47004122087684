import { PillsColor } from "../pills/dto"

export interface DocumentCreationDTO {
    expirationDate: string,
    note: string,
    fileName: string,
    type: string,
    companyId: number,
    period: string,
    year: string,
    activityTypeId: string
    senderGroup: 'accountants' | 'operators' | 'companies' | 'admin'
    receiverGroup: ('accountants' | 'operators' | 'companies')[]
}

export interface FindAllCompaniesFilters {
    accountant: string,
    financialAdvisor: string,
    businessName: string,
    itemsPerPage: number,
    page: number,
    status: CompanyStatusEnum[]
}

export enum CompanyStatusEnum {
    Pending = 'Pending',
    RegistryPending = 'RegistryPending',
    Active = 'Active'
}

export interface FindAllCompanies {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: CompanyResponseDTO[]
}

export interface CompanyResponseDTO {
    id: number,
    userId: string | null,
    email: string | null,
    businessName: string | null,
    premium: boolean,
    price: number,
    state: CompanyStatusEnum,
    annualForeignInvoices: number,
    atecoCodesNumber: number,
    avatarObjectId: string | null,
    activityCategoryIds: number[] | null,
    todos: string[] | null,
    professionistWithFund: boolean,
    specialVatSchemes: boolean,
    banksNumber: number,
    journal: number,
    financialAdvisorId: string,
    accountantId: string,
    name: string | null,
    surname: string | null,
    fiscalCode: string | null,
    vatNumber: string | null,
    note: string | null,
    employeesNumber: number | null,
    phoneNumber: string | null,
    bankingAccounts: string[]
    activityTypeIds: number[],
    salesRegister: string[] | null
    purchasesRegister: string[] | null
    feesRegister: string[] | null
    registerCharge: boolean | null
    registerChargeName: string | null
    admins: string[] | null
    members: string[],
    atecoCodeIds: number[]
    loansObjectIds: number[] | null
    fundingObjectIds: number[] | null
    leasingObjectIds: number[] | null
    instrumentalVehicles: boolean | null,
    instrumentalVehiclesLicensePlate: string | null,
    notInstrumentalVehicles: boolean | null,
    notInstrumentalVehiclesLicensePlate: string | null,
    retentionManagement: boolean | null
    acubeAccounts: string[],
    periodicity: PeriodicityEnum
    activityNotifications: boolean
    operationsNotifications: boolean
}

export enum PeriodicityEnum {
    Monthly = 'Monthly',
    Quarterly = 'Quarterly'
}

export interface CompanyUpdateRegistryDTO {
    businessName: string,
    name?: string,
    surname?: string,
    vatNumber?: string,
    fiscalCode?: string,
    email: string,
    phoneNumber?: string,
    employeesNumber?: number,
    note?: string,
    premium: boolean
}

export interface CreateOperatorRequestDTO {
    name: string,
    surname: string
    email: string
    phoneNumber: string
    description: string
    financialAdvisorId: number
}
export interface OperatorResponseDTO {
    id: number
    name: string,
    surname: string
    email: string
    phoneNumber: string
    description: string
    financialAdvisorId: number
    avatarObjectId: number | null
    companyIds: number[] | null
}

export interface FindAllOperators {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: OperatorResponseDTO[]
}
export interface AddOperatorDTO {
    operatorIds: number[]
}
export interface AddAtecoDTO {
    atecoCodeId: number
}
export interface AddAccountantIdDTO {
    accountantId: string
}
export interface FindAllAtecos {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: AtecoResponseDTO[]
}
export interface AtecoResponseDTO {
    id: number,
    code: string,
    description: string,
    coefficientOfProfitability: number
}

export interface AddBankingAccountDTO {
    bankingAccounts: string[]
}

export const CompanyStatusMap = new Map<CompanyStatusEnum, { color: PillsColor, label: string }>([
    [CompanyStatusEnum.Active, { color: PillsColor.SUCCESS, label: 'Attiva' }],
    [CompanyStatusEnum.Pending, { color: PillsColor.WARNING, label: 'Da accettare' }],
    [CompanyStatusEnum.RegistryPending, { color: PillsColor.WARNING, label: 'In attesa' }]
])
export interface BankRequestModel {
    name: string
    iban: string
}
export interface BankRequestDTO {
    bankingAccounts: string[]
}

export interface ActivityTypesRequestDTO {
    activityTypeIds: number[]
}

export interface TodosDTO {
    todos: string[]
}

export interface ActivityCanBeDeletedResponse {
    activityTypeId: number,
    canBeDeleted: boolean
}

export interface ActivityGroupDTO {
    id: number,
    dueDate: Date,
    period: number,
    year: number,
    state: ActivityGroupStatus,
    activityCategoryId: number,
    companyId: number
}

export interface ActivityGroupResponse {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: ActivityGroupDTO[]
}

export enum ActivityGroupStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Expired = 'Expired',
}

export enum ActivityStatus {
    Pending = 'Pending',
    Completed = 'Completed',
}

export interface ActivityDTO {
    id: number,
    state: ActivityStatus,
    activityGroupId: number,
    activityTypeId: number,
    documentIds: number[]
}

export interface ActivityResponse {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: ActivityDTO[]
}

export interface ActivityTypeDTO {
    id: number,
    name: string,
    activityCategoryId: number
}

export interface CompanyRegistryDTO {
    salesRegisterNumber: number,
    salesRegister: string[],
    purchasesRegisterNumer: number,
    purchasesRegister: string[],
    feesRegisterNumber: number,
    feesRegister: string[],
    registerCharge: boolean,
    registerChargeName: string,
    adminsNumber: number,
    admins: string[],
    membersNumber: number,
    members: string[],
    instrumentalVehicles: boolean,
    instrumentalVehiclesLicensePlate: string,
    notInstrumentalVehicles: boolean,
    notInstrumentalVehiclesLicensePlate: string,
    loansNumber: number,
    fundingNumber: number,
    leasingNumber: number,
    retentionManagement: boolean
}
export interface CompanyUpdateDTO {
    name?: string,
    surname?: string,
    employeesNumber?: number,
    vatNumber?: string,
    fiscalCode?: string,
    phoneNumber?: string,
    note?: string,
    premium?: boolean
}
export interface CompanyUnseenInfoDTO {
    companyId: number
    unseenRecived: number
    unseenSent: number
}