import axios from "axios";
import { format, lastDayOfMonth } from "date-fns";
import { keycloak } from "../keycloak";
import { DocumentsResponseDTO, FindAllDocumentsResponse } from "../objects/dto";
import { CompanyResponseDTO, CompanyUnseenInfoDTO, FindAllAtecos } from "./dto";
import { CompanyService } from "./service";

export class CompanyServiceImpl implements CompanyService {

    public findCompany(id: string): Promise<CompanyResponseDTO> {
        let url: string = "/api/companies/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findAllAtecos(): Promise<FindAllAtecos> {
        let url: string = "/api/ateco-codes/"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findAllDocuments(companyId: number, year: number, period: number | '', page: number, itemsPerPage: number, received: 'true' | 'false'): Promise<FindAllDocumentsResponse> {
        const monthMap = new Map<string, number>([
            ['0', 0],
            ['1', 1],
            ['2', 2],
            ['3', 3],
            ['4', 4],
            ['5', 5],
            ['6', 6],
            ['7', 7],
            ['8', 8],
            ['9', 9],
            ['10', 10],
            ['11', 11]
        ])
        let fromDate: string = ''
        let toDate: string = ''
        if (period === '') {
            fromDate = year + '-01-01'
            toDate = year + '-12-31'
        } else {
            fromDate = format(new Date(year, period, 1), 'yyyy-MM-dd')
            toDate = format(lastDayOfMonth(new Date(year, period, 1)), 'yyyy-MM-dd')
        }
        let url: string =
            "/api/document?companyId=" + companyId +
            "&fromDate=" + fromDate +
            "&toDate=" + toDate +
            "&page=" + page +
            "&itemsPerPage=" + itemsPerPage +
            "&received=" + received
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findDocumentById(id: string): Promise<DocumentsResponseDTO> {
        let url: string = "/api/document/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteDocument(id: string): Promise<void> {
        let url: string = "/api/document/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public getCompanyUnseenInfoDocuments(): Promise<CompanyUnseenInfoDTO[]> {
        let url: string = "/api/companies/unseenDocuments"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
