import { createSlice } from "@reduxjs/toolkit"
import { MenuItems } from "./dto"

interface SidebarState {
    open: boolean
    menuItems: MenuItems[]
}

const initialState: SidebarState = {
    open: true,
    menuItems: []
}

const sidebarSlice = createSlice({
    name: 'sidebar/slice',
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload
        },
        setMenuItems: (state, action) => {
            state.menuItems = action.payload
        }
    },
})

export const {
    setOpen,
    setMenuItems
} = sidebarSlice.actions

export default sidebarSlice.reducer