import { MenuItem } from "@mui/material";
import { ReactNode } from "react";
import colors from '../utils/index.module.scss';

interface Props {
    children: ReactNode
    onClick?: React.MouseEventHandler<HTMLLIElement>
}

export function MenuItemComponent(props: Props) {
    const { children } = props
    const { onClick } = props

    return (
        <MenuItem
            sx={{
                padding: '8px 12px',
                borderRadius: '8px',
                border: '1px solid ' + colors.white,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '8px',
                '&:hover': {
                    backgroundColor: colors.neutral20,
                    border: '1px solid ' + colors.neutral80
                },
                '&:active': {
                    backgroundColor: colors.primary50,
                    border: '1px solid ' + colors.primary500
                },
            }}
            onClick={onClick} classes={{ root: 'text text--lg typography--medium typography-neutral--400' }}>
            {children}
        </MenuItem>
    )
}