import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { findEvents } from "../events/slice";
import { HeadingSize } from "../heading/dto";
import { ChatIcon } from "../icons/chat";
import { ChevronRightIcon } from "../icons/chevronRight";
import { LayoutComponent } from "../layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import colors from '../utils/index.module.scss';
import { DashboardPremium } from "./premium";
import { DashboardStandard } from "./standard";

export function DashboardView() {
    const receivedMessages = useAppSelector(state => state.chat.companyUnseenMessagesResponse[0])
    const eventsState = useAppSelector(state => state.events)
    const authState = useAppSelector(state => state.auth)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (eventsState.updateEventStatusStatus === 'successfully')
            dispatch(findEvents(eventsState.findEventsFilters))
    }, [eventsState.updateEventStatusStatus])

    return (
        <LayoutComponent menuItem={MenuItems.DASHBOARD} headingLabel={"Dashboard"} headingButtons={[]} breadcrumbItems={['Dashboard']} headingSize={HeadingSize.LG}>
            <div style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: "flex-start",
                gap: "24px",
            }}>
                <div style={{
                    display: "flex",
                    padding: "16px 24px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "12px",
                    borderRadius: "24px",
                    border: "1px solid " + colors.primary100,
                    width: '100%'
                }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingLeft: "4px",
                            gap: "8px",
                            width: '100%'
                        }}
                    >
                        <ChatIcon colorBase={colors.neutral200} />
                        <span style={{ flexGrow: 1 }} className="heading heading--xs typography--bold typography-primary--800">Ultimo messaggio</span>
                        <ButtonComponent label={""} onClick={() => navigate('/messages')} color={Colors.PRIMARY} variant={Variant.LINK} size={Size.SM} iconStyle={IconStyle.ONLY} icon={<ChevronRightIcon colorBase={""} />} />
                    </div>
                    <div
                        className="text text--lg typography--regular typography-neutral--500 text-overflow"
                        style={{
                            paddingTop: "12px",
                            borderTop: "1px dashed " + colors.primary100,
                            width: '100%',
                        }}>
                        {receivedMessages.unseen === 0 || receivedMessages === null || receivedMessages.lastMessage === null ? 'Non ci sono ancora messaggi.' : receivedMessages.lastMessage}
                    </div>
                </div>
                {
                    authState.findMeResponse?.premium ?
                        <DashboardPremium /> :
                        <DashboardStandard />
                }
            </div>
        </LayoutComponent >
    )
}