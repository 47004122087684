import axios from "axios";
import { CompanyResponseDTO } from "../documents/dto";
import { keycloak } from "../keycloak";
import { AcubeAccountDTO, AcubeReconnectResponseDTO, EditNotificationsDTO, FinancialAdvisorResponseDTO } from "./dto";
import { AuthService } from "./service";

export class AuthServiceImpl implements AuthService {

    public findMe(): Promise<CompanyResponseDTO> {
        let url: string = "/api/users/me"

        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findFinancialAdvisor(id: string): Promise<FinancialAdvisorResponseDTO> {
        let url: string = "/api/financial-advisors/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findByIdAcubeGetAccount(id: string, uuid: string): Promise<AcubeAccountDTO> {
        let url: string = "/api/companies/" + id + "/acubeGetAccount/" + uuid
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public acubeGetTransaction(fiscalId: string, uuid: string, madeOnAfter: string, madeOnBefore: string): Promise<string> {
        let url: string = "/api/companies/" + fiscalId + "/acubeGetTransactions?account=" + uuid + "&madeOnAfter=" + madeOnAfter + "&madeOnBefore=" + madeOnBefore
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public acubeReconnectRequest(id: string, uuid: string): Promise<AcubeReconnectResponseDTO> {
        let url: string = "/api/companies/" + id + "/acubeReconnectRequest/" + uuid
        return axios({
            url: url,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findAcubeConnectRequest(id: string): Promise<string> {
        let url: string = "/api/companies/" + id + "/acubeConnectRequest"
        return axios({
            url: url,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editMe(id: string, data: EditNotificationsDTO): Promise<void> {
        let url: string = "/api/companies/" + id + "/editNotifications" 
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
