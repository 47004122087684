import { useEffect } from "react";
import { HeadingSize } from "../heading/dto";
import { LayoutComponent } from "../layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { ChatComponent } from "./chat";
import { findChats, setSelectedChat } from "./slice";
import { UsersList } from "./usersList";

export function ChatViewV2() {
    const authState = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setSelectedChat(0))
    }, [])

    useEffect(() => {
        if (authState.findMeStatus === 'successfully' && authState.findMeResponse !== undefined) {
            dispatch(findChats({ firstUser: '', secondUser: '', topic: authState.findMeResponse.id }))
        }
    }, [authState.findMeStatus])

    return (
        <LayoutComponent menuItem={MenuItems.CHAT} headingLabel={"Messaggi"} headingButtons={[]} breadcrumbItems={['Messaggi']} headingSize={HeadingSize.LG} >
            <div style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: "flex-start",
                gap: "24px",
            }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 16, height: 'calc(100vh - 250px)' }}>
                    <UsersList />
                    <ChatComponent />
                </div>
            </div>
        </LayoutComponent >
    )
}