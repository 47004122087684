import { useEffect, useState } from "react";
import { IconsProps } from "./dto";

export function BreadcrumbsIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg cursor={'pointer'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.475 18.9584H7.475C2.95 18.9584 1.01666 17.025 1.01666 12.5V7.50002C1.01666 2.97502 2.95 1.04169 7.475 1.04169H12.475C17 1.04169 18.9333 2.97502 18.9333 7.50002V12.5C18.9333 17.025 17.0083 18.9584 12.475 18.9584ZM7.475 2.29169C3.63333 2.29169 2.26666 3.65835 2.26666 7.50002V12.5C2.26666 16.3417 3.63333 17.7084 7.475 17.7084H12.475C16.3167 17.7084 17.6833 16.3417 17.6833 12.5V7.50002C17.6833 3.65835 16.3167 2.29169 12.475 2.29169H7.475Z" fill={color} />
            <path d="M6.64166 18.9584C6.3 18.9584 6.01666 18.675 6.01666 18.3334V1.66669C6.01666 1.32502 6.3 1.04169 6.64166 1.04169C6.98333 1.04169 7.26666 1.32502 7.26666 1.66669V18.3334C7.26666 18.675 6.99166 18.9584 6.64166 18.9584Z" fill={color} />
            <path d="M12.475 12.7584C12.3167 12.7584 12.1583 12.7 12.0333 12.575L9.9 10.4417C9.65833 10.2 9.65833 9.80002 9.9 9.55836L12.0333 7.42502C12.275 7.18336 12.675 7.18336 12.9167 7.42502C13.1583 7.66669 13.1583 8.06669 12.9167 8.30836L11.2333 10L12.925 11.6917C13.1667 11.9334 13.1667 12.3334 12.925 12.575C12.8 12.7 12.6417 12.7584 12.475 12.7584Z" fill={color} />
        </svg>
    )
}