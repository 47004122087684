import { useEffect, useState } from "react"
import { IconsProps } from "./dto"

export function LayersIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path d="M2.33331 12.0834L10.6667 16.25L19 12.0834M10.6667 3.75L2.33331 7.91668L10.6667 12.0834L19 7.91668L10.6667 3.75Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
