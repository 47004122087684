import { useEffect, useState } from "react";
import { IconsProps } from "./dto";

export function LifeBuoyIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg
            onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_361_13659)">
                <path d="M4.10829 4.10829L7.64163 7.64163M12.3583 12.3583L15.8916 15.8916M15.8916 4.10829L12.3583 7.64163L15.3 4.69996M4.10829 15.8916L7.64163 12.3583M18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996ZM13.3333 9.99996C13.3333 11.8409 11.8409 13.3333 9.99996 13.3333C8.15901 13.3333 6.66663 11.8409 6.66663 9.99996C6.66663 8.15901 8.15901 6.66663 9.99996 6.66663C11.8409 6.66663 13.3333 8.15901 13.3333 9.99996Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_361_13659">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}