import { SxProps } from "@mui/material";
import { AvatarSize } from "./dto";

export const AvatarSizeMap = new Map<AvatarSize, { icon: SxProps, text: string, subText: string }>([
    [AvatarSize.XS, {
        icon: {
            height: '24px',
            width: '24px',
            borderRadius: '120px',
            objectFit: 'cover'
        },
        text: 'text text--sm typography--medium typography-neutral--800',
        subText: 'text text--sm typography--regular typography-neutral--500'
    }],
    [AvatarSize.SM, {
        icon: {
            height: '32px',
            width: '32px',
            borderRadius: '160px',
            objectFit: 'cover'
        },
        text: 'text text--md typography--medium typography-neutral--800',
        subText: 'text text--sm typography--regular typography-neutral--500'
    }],
    [AvatarSize.MD, {
        icon: {
            height: '40px',
            width: '40px',
            borderRadius: '200px',
            objectFit: 'cover'
        },
        text: 'text text--lg typography--medium typography-neutral--800',
        subText: 'text text--md typography--regular typography-neutral--500'
    }],
    [AvatarSize.XXL, {
        icon: {
            height: '64px',
            width: '64px',
            borderRadius: '280px',
            objectFit: 'cover'
        },
        text: 'heading heading--xs typography--medium typography-neutral--800',
        subText: 'text text--lg typography--regular typography-neutral--500'
    }],
])