import { ButtonComponent } from "../button"
import { Colors, IconStyle, Size, Variant } from "../button/dto"
import { ModalComponent } from "../modal"
import colors from '../utils/index.module.scss'

interface Props {
    open: boolean
    handleClose: (open: boolean) => void
    title: string
    description: string
    labelDeny: string
    labelConfirm: string
    actionConfirm: Function
}

export function ConfirmOrDenyModal(props: Props) {
    const { open } = props
    const { handleClose } = props
    const { title } = props
    const { description } = props
    const { labelDeny } = props
    const { labelConfirm } = props
    const { actionConfirm } = props

    return (
        <ModalComponent
            open={open}
            handleClose={handleClose}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                <div className="d-flex align-items-center justify-content-center">
                    <span className="text text--lg typography--semibold">{title}</span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-between" style={{ gap: '16px', width: '100%' }}>
                    <span className="text text--lg typography--regular typography-neutral--500">{description}</span>
                </div>
                <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                    <div
                        className="col"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <ButtonComponent
                            label={labelDeny}
                            onClick={() => handleClose(false)}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div
                        className="col"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <ButtonComponent
                            label={labelConfirm}
                            onClick={() => actionConfirm()}
                            color={Colors.PRIMARY}
                            variant={Variant.SOLID}
                            size={Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                </div>
            </div>
        </ModalComponent >
    )
}