import { CompanyResponseDTO } from "../documents/dto";
import { AcubeAccountDTO, AcubeReconnectResponseDTO, EditNotificationsDTO, FinancialAdvisorResponseDTO } from "./dto";
import { AuthServiceImpl } from "./serviceImpl";

export interface AuthService {
    findMe(): Promise<CompanyResponseDTO>
    findFinancialAdvisor(id: string): Promise<FinancialAdvisorResponseDTO>
    findByIdAcubeGetAccount(id: string, uuid: string): Promise<AcubeAccountDTO>
    acubeGetTransaction(fiscalId: string, uuid: string, madeOnAfter: string, madeOnBefore: string): Promise<string>
    acubeReconnectRequest(id: string, uuid: string): Promise<AcubeReconnectResponseDTO>
    findAcubeConnectRequest(id: string): Promise<string>
    editMe(id: string, data: EditNotificationsDTO): Promise<void>
}

export function NewAuthService(): AuthService {
    return new AuthServiceImpl();
}
