export enum AvatarType {
    SINGLE = 'SINGLE',
    USER = 'USER'
}

export enum AvatarSize {
    XS = 'XS',
    SM = 'SM',
    MD = 'MD',
    XXL = 'XXL'
}