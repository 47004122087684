import { CloseIcon } from '../icons/close';
import colors from '../utils/index.module.scss';
import './style.css';

interface Props {
  active: boolean;
  close: () => void;
  message?: string;
}

export function SuccessPopup(props: Props) {
  const { active } = props;
  const { close } = props;
  const { message } = props;

  if (!active) {
    return null;
  }

  setTimeout(() => close(), 4000);

  return (
    <div className={'popup-success d-flex align-items-center justify-content-between p-3'}>
      <div>
        <span className={'text text--lg typography--medium typography--white p-0 m-0'}>{message}</span>
      </div>
      <CloseIcon colorBase={colors.white} />
    </div>
  );
}
