import axios from "axios";
import { ActivityDTO } from "../documents/dto";
import { keycloak } from "../keycloak";
import { ActivityCategory, ActivityTypeCreationDTO, ActivityTypeFilters, FindAllActivityCategory, FindAllActivityType } from "./dto";
import { ActivityService } from "./service";

export class ActivityServiceImpl implements ActivityService {

    public findAllActivityCategory(): Promise<FindAllActivityCategory> {
        let url: string = "/api/activity-categories?" +
            "page=0&itemsPerPage=0"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findAllActivityType(filters: ActivityTypeFilters): Promise<FindAllActivityType> {
        let url: string = "/api/activity-types?" +
            "page=0&itemsPerPage=0" +
            (filters.activityCategoryId !== '' ? "&activityCategoryId=" + filters.activityCategoryId : '') +
            (filters.name !== '' ? "&name=" + filters.name : '')
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createActivityType(data: ActivityTypeCreationDTO): Promise<void> {
        let url: string = "/api/activity-types"
        return axios({
            url: url,
            method: "POST",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findActivityCategory(id: number): Promise<ActivityCategory> {
        let url: string = "/api/activity-categories/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findActivity(id: number): Promise<ActivityDTO> {
        let url: string = "/api/activities/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

}
