import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CompanyResponseDTO } from "../documents/dto"
import { NewObjectService } from "../objects/service"
import { PromiseStatuses } from "../utils"
import { AcubeAccountDTO, AcubeReconnectResponseDTO, EditNotificationsDTO, FinancialAdvisorResponseDTO } from "./dto"
import { NewAuthService } from "./service"

interface AuthState {
    findMeResponse?: CompanyResponseDTO
    findMeStatus: PromiseStatuses
    findAvatarResponse: string
    findAvatarStatus: PromiseStatuses
    findFinancialAdvisorResponse?: FinancialAdvisorResponseDTO
    findFinancialAdvisorStatus: PromiseStatuses
    findFinancialAdvisorAvatarResponse: string
    findFinancialAdvisorAvatarStatus: PromiseStatuses
    findByIdAcubeGetAccountStatus: PromiseStatuses
    findByIdAcubeGetAccountResponse: AcubeAccountDTO[]
    openDownloadPdfAcubeAccountsModal: boolean
    acubeGetTransactionsResponse: string
    acubeGetTransactionsStatus: PromiseStatuses
    acubeReconnectRequestStatus: PromiseStatuses
    acubeReconnectRequestResponse?: AcubeReconnectResponseDTO
    findAcubeConnectRequestStatus: PromiseStatuses
    findAcubeConnectRequestResponse: string
    editMeRequest: EditNotificationsDTO
    editMeStatus: PromiseStatuses
}

const initialState: AuthState = {
    findMeStatus: 'idle',
    findAvatarResponse: '',
    findAvatarStatus: 'idle',
    findFinancialAdvisorStatus: 'idle',
    findFinancialAdvisorAvatarResponse: '',
    findFinancialAdvisorAvatarStatus: 'idle',
    findByIdAcubeGetAccountResponse: [],
    findByIdAcubeGetAccountStatus: 'idle',
    openDownloadPdfAcubeAccountsModal: false,
    acubeGetTransactionsResponse: '',
    acubeGetTransactionsStatus: 'idle',
    acubeReconnectRequestStatus: 'idle',
    findAcubeConnectRequestStatus: 'idle',
    findAcubeConnectRequestResponse: '',
    editMeRequest: {},
    editMeStatus: 'idle'
}

export const findMe = createAsyncThunk(
    'auth/findMe',
    async (): Promise<CompanyResponseDTO> => {
        const authService = NewAuthService()

        return authService.findMe()
    }
)

export const findAvatar = createAsyncThunk(
    'auth/findAvatar',
    async (id: string): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(id)
    }
)

export const findFinancialAdvisor = createAsyncThunk(
    'auth/findFinancialAdvisor',
    async (id: string): Promise<FinancialAdvisorResponseDTO> => {
        const authService = NewAuthService()

        return authService.findFinancialAdvisor(id)
    }
)

export const findFinancialAdvisorAvatar = createAsyncThunk(
    'auth/findFinancialAdvisorAvatar',
    async (id: string): Promise<string> => {
        if (id === null) {
            return Promise.resolve('')
        }
        const objectService = NewObjectService()

        return objectService.findFileById(id)
    }
)

export const findByIdAcubeGetAccount = createAsyncThunk(
    'company/findByIdAcubeGetAccount',
    async (request: { id: string, uuid: string[] }): Promise<AcubeAccountDTO[]> => {
        let promises: Promise<AcubeAccountDTO>[] = []
        promises = request.uuid.map(async (id) => {
            const authService = NewAuthService()

            return authService.findByIdAcubeGetAccount(request.id, id)
        })
        return Promise.all(promises)
    }
)

export const acubeGetTransactions = createAsyncThunk(
    'company/acubeGetTransactions',
    async (request: { fiscalId: string, uuid: string, madeOnAfter: string, madeOnBefore: string }, thunkApi): Promise<string> => {
        const companyService = NewAuthService()

        return companyService.acubeGetTransaction(request.fiscalId, request.uuid, request.madeOnAfter, request.madeOnBefore)
    }
)

export const acubeReconnectRequest = createAsyncThunk(
    'company/acubeReconnectRequest',
    async (request: { id: string, uuid: string }, thunkApi): Promise<AcubeReconnectResponseDTO> => {
        const companyService = NewAuthService()

        return companyService.acubeReconnectRequest(request.id, request.uuid)
    }
)

export const findAcubeConnectRequest = createAsyncThunk(
    'company/findAcubeConnectRequest',
    async (id: string): Promise<string> => {
        const companyService = NewAuthService()

        return companyService.findAcubeConnectRequest(id)
    }
)

export const editMe = createAsyncThunk(
    'company/editMe',
    async (request: { id: string, data: EditNotificationsDTO }): Promise<void> => {
        const companyService = NewAuthService()

        return companyService.editMe(request.id, request.data)
    }
)

const authSlice = createSlice({
    name: 'auth/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findMe.pending, (state) => {
                state.findMeStatus = 'loading'
            })
            .addCase(findMe.fulfilled, (state, action) => {
                state.findMeResponse = action.payload
                state.findMeStatus = 'successfully'
            })
            .addCase(findMe.rejected, (state) => {
                state.findMeStatus = 'failed'
            })
            .addCase(findAvatar.pending, (state) => {
                state.findAvatarStatus = 'loading'
            })
            .addCase(findAvatar.fulfilled, (state, action) => {
                state.findAvatarResponse = action.payload
                state.findAvatarStatus = 'successfully'
            })
            .addCase(findAvatar.rejected, (state) => {
                state.findAvatarStatus = 'failed'
            })
            .addCase(findFinancialAdvisor.pending, (state) => {
                state.findFinancialAdvisorStatus = 'loading'
            })
            .addCase(findFinancialAdvisor.fulfilled, (state, action) => {
                state.findFinancialAdvisorResponse = action.payload
                state.findFinancialAdvisorStatus = 'successfully'
            })
            .addCase(findFinancialAdvisor.rejected, (state) => {
                state.findFinancialAdvisorStatus = 'failed'
            })
            .addCase(findFinancialAdvisorAvatar.pending, (state) => {
                state.findFinancialAdvisorAvatarStatus = 'loading'
            })
            .addCase(findFinancialAdvisorAvatar.fulfilled, (state, action) => {
                state.findFinancialAdvisorAvatarResponse = action.payload
                state.findFinancialAdvisorAvatarStatus = 'successfully'
            })
            .addCase(findFinancialAdvisorAvatar.rejected, (state) => {
                state.findFinancialAdvisorAvatarStatus = 'failed'
            })
            .addCase(findByIdAcubeGetAccount.pending, (state) => {
                state.findByIdAcubeGetAccountStatus = 'loading'
            })
            .addCase(findByIdAcubeGetAccount.fulfilled, (state, action) => {
                state.findByIdAcubeGetAccountStatus = 'successfully'
                state.findByIdAcubeGetAccountResponse = action.payload
            })
            .addCase(findByIdAcubeGetAccount.rejected, (state) => {
                state.findByIdAcubeGetAccountStatus = 'failed'
            })
            .addCase(acubeGetTransactions.pending, (state) => {
                state.acubeGetTransactionsStatus = 'loading'
            })
            .addCase(acubeGetTransactions.fulfilled, (state, action) => {
                state.acubeGetTransactionsStatus = 'successfully'
                state.acubeGetTransactionsResponse = action.payload
            })
            .addCase(acubeGetTransactions.rejected, (state) => {
                state.acubeGetTransactionsStatus = 'failed'
            })
            .addCase(acubeReconnectRequest.pending, (state) => {
                state.acubeReconnectRequestStatus = 'loading'
            })
            .addCase(acubeReconnectRequest.fulfilled, (state, action) => {
                state.acubeReconnectRequestStatus = 'successfully'
                state.acubeReconnectRequestResponse = action.payload
            })
            .addCase(acubeReconnectRequest.rejected, (state) => {
                state.acubeReconnectRequestStatus = 'failed'
            })
            .addCase(findAcubeConnectRequest.pending, (state) => {
                state.findAcubeConnectRequestStatus = 'loading'
            })
            .addCase(findAcubeConnectRequest.fulfilled, (state, action) => {
                state.findAcubeConnectRequestStatus = 'successfully'
                state.findAcubeConnectRequestResponse = action.payload
            })
            .addCase(findAcubeConnectRequest.rejected, (state) => {
                state.findAcubeConnectRequestStatus = 'failed'
            })
            .addCase(editMe.pending, (state) => {
                state.editMeStatus = 'loading'
            })
            .addCase(editMe.fulfilled, (state) => {
                state.editMeStatus = 'successfully'
            })
            .addCase(editMe.rejected, (state) => {
                state.editMeStatus = 'failed'
            })
    },
    reducers: {
        setOpenDownloadPdfAcubeAccountsModal: (state, action) => {
            state.openDownloadPdfAcubeAccountsModal = action.payload
        },
        setAcubeGetTransactionsStatus: (state, action) => {
            state.acubeGetTransactionsStatus = action.payload
        },
        setAcubeReconnectRequestStatus: (state, action) => {
            state.acubeReconnectRequestStatus = action.payload
        },
        setFindAcubeConnectRequestStatus: (state, action) => {
            state.findAcubeConnectRequestStatus = action.payload
        },

        setEditMeActivityNotification: (state, action) => {
            state.editMeRequest.activityNotifications = action.payload
        },
        setEditMeOperationsNotification: (state, action) => {
            state.editMeRequest.operationsNotifications = action.payload
        },
        resetEditMeRequest: (state) => {
            state.editMeRequest = {
                activityNotifications: undefined,
                operationsNotifications: undefined,
            }
        },
        setEditMeStatus: (state, action) => {
            state.editMeStatus = action.payload
        },
        setFindAvatarStatus: (state, action) => {
            state.findAvatarStatus = action.payload
        }
    },
})

export const {
    setOpenDownloadPdfAcubeAccountsModal,
    setAcubeGetTransactionsStatus,
    setAcubeReconnectRequestStatus,
    setFindAcubeConnectRequestStatus,
    setEditMeActivityNotification,
    setEditMeOperationsNotification,
    resetEditMeRequest,
    setEditMeStatus,
    setFindAvatarStatus
} = authSlice.actions

export default authSlice.reducer