import { useEffect, useState } from "react"
import colors from '../utils/index.module.scss'
import { IconsProps } from "./dto"

export function ArchiveAlertIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: colors.primary500, position: 'absolute', top: '2px', left: '13px' }} />
            <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
                onMouseOut={() => setColor(props.colorBase)}
                width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 8V21H3V8M10 12H14M1 3H23V8H1V3Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg >
        </div>
    )
}