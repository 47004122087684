import { Size } from "../button/dto";

export enum HeadingSize {
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
}

export enum HeadingVerticalAlign {
    CENTER = 'CENTER',
    START = 'START'
}

export const headingSizeMap = new Map<HeadingSize, Size>([
    [HeadingSize.SM, Size.SM],
    [HeadingSize.MD, Size.SM],
    [HeadingSize.LG, Size.MD]
])

export const headingTitleMap = new Map<HeadingSize, string>([
    [HeadingSize.LG, 'heading heading--sm typography--semibold typography-neutral--800'],
    [HeadingSize.MD, 'heading heading--xs typography--semibold typography-neutral--800'],
    [HeadingSize.SM, 'text text--xl typography--semibold typography-neutral--800'],
])