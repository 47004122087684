import { useEffect, useState } from "react"
import colors from '../utils/index.module.scss'
import { IconsProps } from "./dto"

export function F24AlertIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: colors.primary500, position: 'absolute', top: '2px', left: '13px' }} />
            <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
                onMouseOut={() => setColor(props.colorBase)}
                width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6667 1.66663H5C4.55797 1.66663 4.13405 1.84222 3.82149 2.15478C3.50893 2.46734 3.33333 2.89127 3.33333 3.33329V16.6666C3.33333 17.1087 3.50893 17.5326 3.82149 17.8451C4.13405 18.1577 4.55797 18.3333 5 18.3333H15C15.442 18.3333 15.866 18.1577 16.1785 17.8451C16.4911 17.5326 16.6667 17.1087 16.6667 16.6666V6.66663M11.6667 1.66663L16.6667 6.66663M11.6667 1.66663V6.66663H16.6667M13.3333 10.8333H6.66667M13.3333 14.1666H6.66667M8.33333 7.49996H6.66667" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}