import { useEffect, useState } from "react"
import colors from '../utils/index.module.scss'
import { IconsProps } from "./dto"

export function UsersAlertIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: colors.primary500, position: 'absolute', top: '2px', left: '13px' }} />
            <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
                onMouseOut={() => setColor(props.colorBase)}
                width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.1667 17.5V15.8333C14.1667 14.9493 13.8155 14.1014 13.1904 13.4763C12.5653 12.8512 11.7174 12.5 10.8334 12.5H4.16671C3.28265 12.5 2.43481 12.8512 1.80968 13.4763C1.18456 14.1014 0.833374 14.9493 0.833374 15.8333V17.5M19.1667 17.5V15.8333C19.1662 15.0948 18.9203 14.3773 18.4678 13.7936C18.0154 13.2099 17.3818 12.793 16.6667 12.6083M13.3334 2.60833C14.0504 2.79192 14.6859 3.20892 15.1397 3.79359C15.5936 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5936 7.29673 15.1397 7.88141C14.6859 8.46608 14.0504 8.88308 13.3334 9.06667M10.8334 5.83333C10.8334 7.67428 9.34099 9.16667 7.50004 9.16667C5.65909 9.16667 4.16671 7.67428 4.16671 5.83333C4.16671 3.99238 5.65909 2.5 7.50004 2.5C9.34099 2.5 10.8334 3.99238 10.8334 5.83333Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}