import { EditEventDTO, EventsFilters, FindAllEventsDTO, NewEventDTO, UpdateEventStatusRequest } from "./dto";
import { EventsServiceImpl } from "./serviceImpl";

export interface EventsService {
    createEvent(request: NewEventDTO): Promise<void>
    findEvents(request: EventsFilters): Promise<FindAllEventsDTO>
    deleteEvent(request: number): Promise<FindAllEventsDTO>
    editEvent(id: number, data: EditEventDTO): Promise<FindAllEventsDTO>
    updateStatus(id: number, data: UpdateEventStatusRequest): Promise<void>
}

export function NewEventsService(): EventsService {
    return new EventsServiceImpl();
}