import { DocumentsResponseDTO, FindAllDocumentsResponse } from "../objects/dto";
import { CompanyResponseDTO, CompanyUnseenInfoDTO, FindAllAtecos } from "./dto";
import { CompanyServiceImpl } from "./serviceImpl";

export interface CompanyService {
    findCompany(id: string): Promise<CompanyResponseDTO>
    findAllDocuments(companyId: number, year: number, period: number | '', page: number, itemsPerPage: number, received: 'true' | 'false'): Promise<FindAllDocumentsResponse>
    findDocumentById(id: string): Promise<DocumentsResponseDTO>
    deleteDocument(id: string): Promise<void>
    findAllAtecos(): Promise<FindAllAtecos>
    getCompanyUnseenInfoDocuments(): Promise<CompanyUnseenInfoDTO[]>
}

export function NewCompanyService(): CompanyService {
    return new CompanyServiceImpl();
}
