import { Table, TableContainer } from "@mui/material"
import { ReactNode } from "react"
import colors from '../utils/index.module.scss'

interface Props {
    children: ReactNode
    border?: boolean
}

export function TableComponent(props: Props) {
    const { children } = props
    const { border } = props

    return (
        <TableContainer sx={{
            border: border ? ('1px solid ' + colors.neutral80) : '',
            borderRadius: border ? '8px' : '',
            paddingBottom: '20px'
        }}>
            <Table>
                {children}
            </Table>
        </TableContainer>
    )
}