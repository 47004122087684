import { Chip } from "@mui/material"
import { ReactElement, cloneElement } from "react"
import { PillsColor, PillsEmphasis, PillsIconColor, PillsOutline, PillsSize, PillsSizeMap, PillsStyle } from "./dto"

interface Props {
    label: string
    size: PillsSize
    color: PillsColor
    emphasis: PillsEmphasis
    outline: PillsOutline
    startIcon?: ReactElement
    endIcon?: ReactElement
}

export function PillComponent(props: Props) {
    const { label } = props
    const { size } = props
    const { color } = props
    const { emphasis } = props
    const { outline } = props
    const { startIcon } = props
    const { endIcon } = props

    const Icons = () => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px' }}>
            {
                startIcon && cloneElement(startIcon, { colorBase: PillsIconColor(color, emphasis, outline) })
            }
            {label}
            {
                endIcon && cloneElement(endIcon, { colorBase: PillsIconColor(color, emphasis, outline) })
            }
        </div>
    );

    return (
        <Chip
            classes={{ root: PillsSizeMap.get(size), label: 'p-0' }}
            sx={PillsStyle(color, emphasis, outline, size)}
            icon={<Icons />}
        />
    )
}