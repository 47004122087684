import { ReactNode } from "react";

interface Props {
    value: number
    index: number
    children: ReactNode
}

export function TabPanelComponent(props: Props) {
    const { value } = props;
    const { index } = props;
    const { children } = props;

    return (
        <div
            style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={'simple-tabpanel-' + index}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}