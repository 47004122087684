import { format } from "date-fns";
import { useState } from "react";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { findByIdFiscalDocument, setFindByIdFiscalDocumentStatus } from "../fiscalDocument/slice";
import { CheckCircleIcon } from "../icons/checkCircle";
import { CloseIcon } from "../icons/close";
import { DownloadIcon } from "../icons/download";
import { EditIcon } from "../icons/edit";
import { TrashIcon } from "../icons/trash";
import { NewObjectService } from "../objects/service";
import { findFileById, setFindDocumentStatus } from "../objects/slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner/index";
import colors from '../utils/index.module.scss';
import { EventDTO, EventStatusEnum, EventTypeEnum } from "./dto";
import { deleteEvent, setDeleteEventModalOpen, setEditEventDate, setEditEventId, setEditEventName, setEditEventNote, setModalEventType, setOpenNewEventsModal, updateEventStatus } from "./slice";

interface Props {
    event: EventDTO
}
export function MenuEventComponent(props: Props) {
    const { event } = props

    const dispatch = useAppDispatch()

    const eventsState = useAppSelector(state => state.events)
    const objectState = useAppSelector(state => state.object)
    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)

    const objectService = NewObjectService()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (event === undefined) {
        return <></>
    }

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setOpenNewEventsModal(true))
                    dispatch(setModalEventType('edit'))
                    dispatch(setEditEventName(event.name))
                    dispatch(setEditEventNote(event.note))
                    dispatch(setEditEventId(event.id))
                    dispatch(setEditEventDate(format(new Date(event.date), 'yyyy-MM-dd')))
                    setAnchorEl(null)
                }}>
                <EditIcon colorBase={colors.neutral600} />
                Modifica
            </MenuItemComponent>
            {
                event.type === EventTypeEnum.F24 &&
                < MenuItemComponent
                    onClick={() => {
                        dispatch(findByIdFiscalDocument(event.fiscalDocumentId ? event.fiscalDocumentId.toString() : '')).then((fiscalDocument) => {
                            //@ts-ignore
                            dispatch(findFileById(fiscalDocument.payload.objectId)).then((e) => {
                                //@ts-ignore
                                objectService.downloadBase64WithExtension(fiscalDocument.payload.fileName, e.payload !== null ? e.payload : '')
                                dispatch(setFindDocumentStatus('idle'))
                                dispatch(setFindByIdFiscalDocumentStatus('idle'))
                                handleClose()
                            });
                        })
                    }
                    }>
                    <DownloadIcon colorBase={colors.neutral600} />
                    {
                        objectState.findDocumentStatus === 'loading' || fiscalDocumentState.findByIdFiscalDocumentStatus === 'loading' ?
                            <SpinnerComponent size="small" /> :
                            'Scarica'
                    }
                </MenuItemComponent>
            }
            {
                event.type === EventTypeEnum.F24 &&
                <MenuItemComponent
                    onClick={() => { dispatch(updateEventStatus({ id: event.id, data: { status: event.status === EventStatusEnum.NotResolved ? EventStatusEnum.Resolved : EventStatusEnum.NotResolved } })) }}>
                    {
                        event.status === EventStatusEnum.NotResolved ?
                            <CheckCircleIcon colorBase={colors.neutral600} /> :
                            <CloseIcon colorBase={colors.neutral600} />
                    }
                    {
                        event.status === EventStatusEnum.NotResolved ? 'Risolvi' : 'Da risolvere'
                    }
                </MenuItemComponent>
            }
            <MenuItemComponent
                onClick={() => {
                    dispatch(setDeleteEventModalOpen(true))
                }}>
                <TrashIcon colorBase={colors.neutral600} />
                Cancella
            </MenuItemComponent>
            <ConfirmOrDenyModal
                open={eventsState.deleteEventModalOpen}
                handleClose={() => { dispatch(setDeleteEventModalOpen(false)); handleClose() }}
                title={"Elimina evento"}
                description={"Sei sicuro di voler eliminare questo evento?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    dispatch(deleteEvent(event.id))
                    dispatch(setDeleteEventModalOpen(false))
                    handleClose()
                }}
            />
        </ContextMenuComponent>
    )
}