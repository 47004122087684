import { Select, SelectChangeEvent } from "@mui/material";
import colors from '../utils/index.module.scss';
import './style.scss';

interface Props {
    id: string
    label?: string
    value?: string
    infoText?: boolean
    disabled?: boolean
    defaultValue?: string
    menuItems?: React.ReactNode[]
    onChange?: (event: SelectChangeEvent<string>) => void
    isError?: boolean
    error?: boolean
    required?: boolean
}

export function SelectComponent(props: Props) {
    const { value } = props
    const { label } = props
    const { error } = props
    const { disabled } = props
    const { menuItems } = props
    const { infoText } = props
    const { defaultValue } = props
    const { required } = props
    const { onChange } = props

    return (
        <div style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column', width: '100%' }}>
            {
                label && (
                    <span
                        style={{
                            marginBottom: '6px',
                        }}
                        className="text text--md typography--semibold typography-neutral--700"
                    >
                        {label + (required ? ' *' : '')}
                    </span>
                )
            }
            <Select
                defaultValue={defaultValue}
                variant="standard"
                disabled={disabled}
                value={value}
                onChange={(e) => onChange && onChange(e)}
                displayEmpty
                disableUnderline
                MenuProps={{
                    disableScrollLock: true,
                    classes: { list: 'select-dropdown-menu' }
                }}
                classes={{ select: 'text text--lg typography--medium' + (disabled ? ' typography-neutral--400' : ' typography-neutral--600') }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 0,
                        outline: 0,
                        width: '100%',
                        height: '40px',
                        border: '1px solid ' + (error ? colors['destructive200'] : colors['neutral80']),
                    },
                    width: '100%',
                    height: '40px',
                    border: '1px solid ' + (error ? colors['destructive200'] : colors['neutral80']),
                    borderRadius: '8px',
                    backgroundColor: disabled ? colors['neutral40'] : colors['white'],
                    padding: '8px 12px',
                    '& ::placeholder': {
                        color: colors['neutral400'],
                        fontWeight: 400,
                        opacity: 1
                    },
                    '&:focus': {
                        borderRadius: '8px',
                        backgroundColor: disabled ? colors['neutral40'] : colors['white'],
                        border: '1px solid ' + (error ? colors['destructive200'] : colors['neutral80']),
                    },
                    '& .MuiInputBase-input': {
                        backgroundColor: disabled ? colors['neutral40'] : colors['white'],
                        '&:focus': {
                            backgroundColor: disabled ? colors['neutral40'] : colors['white'],
                        },
                    },
                }}
            >
                {menuItems}
            </Select>
            <div
                className={'text text--md typography--regular' + (error ? ' typography-destructive--400' : ' typography-neutral--400')}>
                {infoText}
            </div>
        </div>
    )
}