import { ChatDTO, ChatFilters, CompanyUnseenMessageDTO, MessageResponse, UserInfoDTO } from "./dto";
import { ChatServiceImpl } from "./serviceImpl";

export interface ChatService {
    findChats(filters: ChatFilters): Promise<ChatDTO[]>
    findChatUser(userId: string): Promise<UserInfoDTO>
    findMessages(chatId: number, page: number, itemsPerPage: number): Promise<MessageResponse>
    setChatSeen(chatId: number): Promise<void>
    getCompanyUnseenInfoMessages(topic: string): Promise<CompanyUnseenMessageDTO[]>
}

export function NewChatService(): ChatService {
    return new ChatServiceImpl();
}