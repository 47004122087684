import { Input, InputAdornment } from "@mui/material";
import { ReactElement, cloneElement, useEffect, useRef } from "react";
import { OnChange, OnKeyDown } from "../utils";
import colors from "../utils/index.module.scss";

interface Props {
    id: string
    type: string
    infoText?: string
    disabled?: boolean
    endIcon?: ReactElement
    startIcon?: ReactElement
    placeholder: string
    error?: boolean
    multiline?: boolean
    max?: number
    defaultValue?: string | number
    value?: string
    label?: string
    required?: boolean
    autofocus?: boolean
    onChange?: OnChange
    onKeyDown?: OnKeyDown
}

export function TextInput(props: Props) {
    const { id } = props
    const { type } = props
    const { infoText } = props
    const { disabled } = props
    const { endIcon } = props
    const { startIcon } = props
    const { placeholder } = props
    const { error } = props
    const { multiline } = props
    const { max } = props
    const { defaultValue } = props
    const { value } = props
    const { label } = props
    const { required } = props
    const { autofocus } = props
    const { onChange } = props
    const { onKeyDown } = props

    const inputReference = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autofocus) {
            if (inputReference.current) {
                inputReference.current.focus()
            }
        }
    }, [])

    return (
        <div style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column', width: '100%' }}>
            {
                multiline && label && (
                    <span
                        style={{
                            marginBottom: '6px',
                        }}
                        className="text text--md typography--semibold typography-neutral--700"
                    >
                        {label + (label && required ? ' *' : '')}
                    </span>
                )
            }
            <Input
                id={id}
                defaultValue={defaultValue}
                disableUnderline
                autoFocus={autofocus}
                placeholder={placeholder + (required ? ' *' : '')}
                disabled={disabled}
                multiline={multiline}
                inputProps={{ maxLength: max }}
                value={value}
                type={type}
                rows={4}
                classes={{ root: 'text text--lg typography--medium' + (disabled ? ' typography-neutral--400' : ' typography-neutral--600') }}
                sx={{
                    padding: '8px 12px',
                    height: !multiline ? '40px' : 'auto',
                    width: '100%',
                    borderRadius: '8px',
                    backgroundColor: disabled ? colors['neutral40'] : colors['white'],
                    border: '1px solid ' + (error ? colors['destructive200'] : colors['neutral80']),
                    '& ::placeholder': {
                        color: colors['neutral200'],
                        fontWeight: 400,
                        opacity: '1 !important'
                    }
                }}
                startAdornment={
                    startIcon && !multiline && (
                        <InputAdornment position="start">
                            {cloneElement(startIcon, { colorBase: colors['neutral200'] })}
                        </InputAdornment>
                    )
                }
                endAdornment={
                    endIcon && !multiline && (
                        <InputAdornment position="end">
                            {cloneElement(endIcon, { colorBase: error ? colors['destzructive400'] : colors['neutral200'] })}
                        </InputAdornment>
                    )
                }
                onChange={(e) => onChange && onChange(e)}
                onKeyDown={(e) => onKeyDown && onKeyDown(e)}
                ref={inputReference}
            />
            {
                infoText && (
                    <div style={{ marginTop: '6px' }}
                        className={'text text--md typography--regular' + (error ? ' typography-destructive--400' : ' typography-neutral--400')}>
                        {infoText}
                    </div>
                )
            }
        </div>
    )
}