import { SxProps } from "@mui/material";
import colors from '../utils/index.module.scss';

export enum PillsSize {
    XS = 'XS',
    SM = 'SM',
    MD = 'MD'
}

export enum PillsColor {
    PRIMARY = 'PRIMARY',
    NEUTRAL = 'NEUTRAL',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    DESTRUCTIVE = 'DESTRUCTIVE'
}

export enum PillsEmphasis {
    HIGH = 'HIGH',
    LOW = 'LOW'
}

export enum PillsOutline {
    FALSE = 'FALSE',
    TRUE = 'TRUE'
}

export const PillsSizeMap = new Map<PillsSize, string>([
    [PillsSize.XS, 'button button--xs typography--semibold p-0'],
    [PillsSize.SM, 'button button--sm typography--semibold p-0'],
    [PillsSize.MD, 'button button--sm typography--semibold p-0'],
])


export function PillsStyle(color: PillsColor, emphasis: PillsEmphasis, outline: PillsOutline, size: PillsSize): SxProps | undefined {
    const colorMap = new Map<PillsColor, SxProps>([
        [PillsColor.NEUTRAL, emphasis === PillsEmphasis.LOW ? {
            backgroundColor: outline === PillsOutline.FALSE ? colors.neutral60 : colors.neutral20,
            color: outline === PillsOutline.FALSE ? colors.neutral800 : colors.neutral600,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.neutral60 : colors.neutral200),
        } : {
            backgroundColor: outline === PillsOutline.FALSE ? colors.neutral500 : colors.neutral20,
            color: outline === PillsOutline.FALSE ? colors.white : colors.neutral700,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.neutral500 : colors.neutral600),
        }
        ],
        [PillsColor.PRIMARY, emphasis === PillsEmphasis.LOW ? {
            backgroundColor: outline === PillsOutline.FALSE ? colors.primary100 : colors.primary50,
            color: outline === PillsOutline.FALSE ? colors.primary800 : colors.primary600,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.primary100 : colors.primary300),
        } : {
            backgroundColor: outline === PillsOutline.FALSE ? colors.primary500 : colors.primary100,
            color: outline === PillsOutline.FALSE ? colors.white : colors.primary700,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.primary500 : colors.primary700),
        }
        ],
        [PillsColor.SUCCESS, emphasis === PillsEmphasis.LOW ? {
            backgroundColor: outline === PillsOutline.FALSE ? colors.success100 : colors.success50,
            color: outline === PillsOutline.FALSE ? colors.success800 : colors.success600,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.success100 : colors.success400),
        } : {
            backgroundColor: outline === PillsOutline.FALSE ? colors.success500 : colors.success100,
            color: outline === PillsOutline.FALSE ? colors.white : colors.success800,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.success500 : colors.success800),
        }
        ],
        [PillsColor.WARNING, emphasis === PillsEmphasis.LOW ? {
            backgroundColor: outline === PillsOutline.FALSE ? colors.warning100 : colors.warning50,
            color: outline === PillsOutline.FALSE ? colors.warning800 : colors.warning600,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.warning100 : colors.warning400),
        } : {
            backgroundColor: outline === PillsOutline.FALSE ? colors.warning500 : colors.warning100,
            color: outline === PillsOutline.FALSE ? colors.white : colors.warning700,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.warning500 : colors.warning600),
        }
        ],
        [PillsColor.DESTRUCTIVE, emphasis === PillsEmphasis.LOW ? {
            backgroundColor: outline === PillsOutline.FALSE ? colors.destructive100 : colors.destructive50,
            color: outline === PillsOutline.FALSE ? colors.destructive800 : colors.destructive600,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.destructive100 : colors.destructive400),
        } : {
            backgroundColor: outline === PillsOutline.FALSE ? colors.destructive500 : colors.destructive100,
            color: outline === PillsOutline.FALSE ? colors.white : colors.destructive700,
            border: '1px solid ' + (outline === PillsOutline.FALSE ? colors.destructive500 : colors.destructive600),
        }
        ]
    ])

    const sizeMap = new Map<PillsSize, SxProps>([
        [PillsSize.XS, {
            height: '20px',
            padding: '0px 6px !important',
            borderRadius: '6px',
        }],
        [PillsSize.SM, {
            height: '24px',
            padding: '0px 8px !important',
            borderRadius: '6px',
        }],
        [PillsSize.MD, {
            height: '28px',
            padding: '0px 10px !important',
            borderRadius: '6px',
        }],
    ])

    return Object.assign({}, colorMap.get(color), sizeMap.get(size))
}

export function PillsIconColor(color: PillsColor, emphasis: PillsEmphasis, outline: PillsOutline): string | undefined {
    const colorMap = new Map<PillsColor, string>([
        [PillsColor.NEUTRAL, emphasis === PillsEmphasis.LOW ? (
            outline === PillsOutline.FALSE ? colors.neutral800 : colors.neutral600
        ) : (
            outline === PillsOutline.FALSE ? colors.white : colors.neutral700)
        ],
        [PillsColor.PRIMARY, emphasis === PillsEmphasis.LOW ? (
            outline === PillsOutline.FALSE ? colors.primary800 : colors.primary600
        ) : (
            outline === PillsOutline.FALSE ? colors.white : colors.primary700)
        ],
        [PillsColor.SUCCESS, emphasis === PillsEmphasis.LOW ? (
            outline === PillsOutline.FALSE ? colors.success800 : colors.success600
        ) : (
            outline === PillsOutline.FALSE ? colors.white : colors.success800)
        ],
        [PillsColor.WARNING, emphasis === PillsEmphasis.LOW ? (
            outline === PillsOutline.FALSE ? colors.warning800 : colors.warning600
        ) : (
            outline === PillsOutline.FALSE ? colors.white : colors.warning700)
        ],
        [PillsColor.DESTRUCTIVE, emphasis === PillsEmphasis.LOW ? (
            outline === PillsOutline.FALSE ? colors.destructive800 : colors.destructive600
        ) : (
            outline === PillsOutline.FALSE ? colors.white : colors.destructive700)
        ]
    ])

    return colorMap.get(color)
}