import { ObjectServiceImpl } from "./serviceImpl";

export interface ObjectService {
    saveDocument(form: FormData): Promise<void>
    findFileById(id: string): Promise<string>
    downloadBase64WithExtension(name: string, bytes: string): void
    downloadBase64WithoutExtension(name: string, bytes: string, type: string): void
    base64ToArrayBuffer(file: string): ArrayBufferLike
    downloadPdf(name: string, bytes: ArrayBuffer, type?: string): void
    base64ToFile(base64String: string): File
    findFileByIdWithUserId(id: string, userid: string): Promise<{ userId: string, objectId: string }>
}

export function NewObjectService(): ObjectService {
    return new ObjectServiceImpl();
}
