import { FindAllFiscalDocumentDTO, FiscalDocumentFilters, FiscalDocumentResponseDTO, UnseenFiscalDocumentsDTO } from "./dto";
import { FiscalDocumentServiceImpl } from "./serviceImpl";

export interface FiscalDocumentService {
    findAllFiscalDocuments(request: FiscalDocumentFilters): Promise<FindAllFiscalDocumentDTO>
    findByIdFiscalDocument(id: string): Promise<FiscalDocumentResponseDTO>
    unseenFiscalDocuments(id: string): Promise<UnseenFiscalDocumentsDTO>
}

export function NewFiscalDocumentService(): FiscalDocumentService {
    return new FiscalDocumentServiceImpl();
}