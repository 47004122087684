import { InputLabel } from "@mui/material"
import { ReactElement } from "react"

interface Props {
    label: string
    labelDescription?: string
    inputs: ReactElement[]
    style: 'row' | 'column'
    required?: boolean
}

export function FormGroup(props: Props) {
    const { label } = props
    const { labelDescription } = props
    const { inputs } = props
    const { style } = props
    const { required } = props

    let parsedInputs: ReactElement[] = [];

    inputs.forEach((input, index) => {
        parsedInputs.push(
            <div
                style={{ margin: 0, padding: 0 }}
                className={"col-12" + (style === 'row' ? " col-lg" : "")}
                key={index}>
                {input}
            </div>
        )
    })

    return (
        <div className="row" style={{
            width: '100%',
            margin: 0,
            padding: 0,
            gap: '6px'
        }}>
            <div style={{ margin: 0, padding: 0 }} className={"col-12" + (style === 'row' ? " col-lg-3" : "")}>
                <InputLabel
                    classes={{ root: "text text--lg typography--semibold typography-neutral--700" }}
                >
                    <p style={{ margin: 0 }}>
                        {label + (label && required ? ' *' : '')}
                        {
                            labelDescription && (
                                <><br /><span
                                    className={'text text--lg typography--regular typography-neutral--500'}>
                                    {labelDescription}
                                </span></>
                            )
                        }
                    </p>
                </InputLabel>
            </div>
            <div style={{ margin: 0, padding: 0 }} className={"col-12" + (style === 'row' ? " col-lg" : "")}>
                <div className="row" style={{ margin: 0, padding: 0, gap: '8px' }}>
                    {parsedInputs}
                </div>
            </div>
        </div>
    )
}