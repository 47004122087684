import { useNavigate } from "react-router-dom"
import { CounterColor, CounterSecondaryComponent } from "../counter/counterSecondary"
import { setDocumentTabValue } from "../documents/slice"
import { CalendarTab } from "../events/calendar"
import { ArchiveIcon } from "../icons/archive"
import { DocumentReceivedIcon } from "../icons/documentReceived"
import { DocumentSentIcon } from "../icons/documentSent"
import { DocumentsIcon } from "../icons/documents"
import { F24Icon } from "../icons/f24"
import { LayersIcon } from "../icons/layers"
import { UsersIcon } from "../icons/users"
import { keycloak } from "../keycloak"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import colors from "../utils/index.module.scss"

export function DashboardPremium() {
    const receivedDocs = useAppSelector(state => state.document.companiesUnseenDocumentsResponse?.find(doc => doc.companyId.toString() !== keycloak.subject)?.unseenRecived)
    const sentDocs = useAppSelector(state => state.document.companiesUnseenDocumentsResponse?.find(doc => doc.companyId.toString() !== keycloak.subject)?.unseenSent)
    const unseenFiscalDocuments = useAppSelector(state => state.fiscalDocument.findUnseenFiscalDocumentsResponse)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    return (
        <div style={{ gap: '24px', display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
                style={{
                    display: "flex",
                    padding: "24px",
                    alignItems: "flex-start",
                    borderRadius: "24px",
                    border: "1px solid " + colors.primary100,
                    gap: "12px",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: "4px",
                        gap: "8px",
                        width: '100%'
                    }}
                >
                    <ArchiveIcon colorBase={colors.neutral200} />
                    <span style={{ flexGrow: 1 }} className="heading heading--xs typography--bold typography-primary--800">Archivi</span>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "8px",
                        width: "100%",
                        flexWrap: 'wrap'
                    }}>
                    <CounterSecondaryComponent color={CounterColor.PRIMARY}
                        icon={<DocumentReceivedIcon colorBase={colors.primary500} />}
                        title={"Documenti ricevuti"}
                        onClick={() => { navigate('/documents'); dispatch(setDocumentTabValue(0)) }}
                        toCount={receivedDocs || 0}
                    />
                    <CounterSecondaryComponent color={CounterColor.PRIMARY}
                        icon={<DocumentSentIcon colorBase={colors.primary500} />}
                        title={"Documenti inviati"}
                        onClick={() => { navigate('/documents'); dispatch(setDocumentTabValue(1)) }}
                        toCount={sentDocs || 0}
                    />
                    <CounterSecondaryComponent color={CounterColor.ORCHID}
                        icon={<F24Icon colorBase={colors.primary500} />}
                        title={"F24"}
                        onClick={() => navigate('/f24')}
                        toCount={unseenFiscalDocuments?.unseenF24 || 0}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "8px",
                        width: "100%",
                        flexWrap: 'wrap'
                    }}>
                    <CounterSecondaryComponent color={CounterColor.SUCCESS}
                        icon={<LayersIcon colorBase={colors.primary500} />}
                        title={"Bilanci e dichiarativi"}
                        onClick={() => navigate('/balance')}
                        toCount={unseenFiscalDocuments?.unseenBalance || 0}
                    />
                    <CounterSecondaryComponent color={CounterColor.WARNING}
                        icon={<UsersIcon colorBase={colors.primary500} />}
                        title={"Dipendenti"}
                        onClick={() => navigate('/employee')}
                        toCount={unseenFiscalDocuments?.unseenEmployees || 0}
                    />
                    <CounterSecondaryComponent color={CounterColor.ERROR}
                        icon={<DocumentsIcon colorBase={colors.primary500} />}
                        title={"Altro"}
                        onClick={() => navigate('/others')}
                        toCount={unseenFiscalDocuments?.unseenOther || 0}
                    />
                </div>
            </div>
            <CalendarTab />
        </div>
    )
}