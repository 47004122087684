import { Modal } from '@mui/base/Modal';
import { Box, Fade, styled } from '@mui/material';
import { ReactElement, forwardRef } from 'react';
import colors from '../utils/index.module.scss';

interface Props {
    children: ReactElement
    open: boolean
    handleClose: (open: boolean) => void
    wide?: boolean
}

export function ModalComponent(props: Props) {
    const { children } = props
    const { open } = props
    const { wide } = props
    const { handleClose } = props

    return (
        <Modal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            slots={{ backdrop: StyledBackdrop }}
            onClose={handleClose}
            closeAfterTransition
            open={open}
            style={{
                position: 'fixed',
                zIndex: '1300',
                right: '0',
                bottom: '0',
                top: '0',
                left: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Fade in={open}>
                <Box sx={style(wide)}>
                    {children}
                </Box>
            </Fade>
        </Modal>
    )
}

const Backdrop = forwardRef<
    HTMLDivElement,
    { open?: boolean; className: string }
>((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={className + (open ? ' MuiBackdrop-open' : '')}
            ref={ref}
            {...other}
        />
    );
});

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background: rgba(17, 18, 24, 0.4);
  backdrop-filter: blur(4px);
  -webkit-tap-highlight-color: transparent;
`;

const style = (wide?: boolean) => ({
    width: wide ? 800 : 400,
    borderRadius: '16px',
    backgroundColor: 'white',
    boxShadow: colors.boxShadowMD,
});
