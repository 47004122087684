import { MenuItem, TableBody } from "@mui/material";
import { format } from "date-fns";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { DownloadIcon } from "../../icons/download";
import { SearchIcon } from "../../icons/search";
import { InfoTextPopup } from "../../infoTextPopup";
import { NewObjectService } from "../../objects/service";
import { findFileById, setFindDocumentStatus } from "../../objects/slice";
import { PillComponent } from "../../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../../pills/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SelectComponent } from "../../select";
import { SpinnerComponent } from "../../spinner";
import { TableComponent } from "../../table";
import { TableCellComponent } from "../../table/tableCell";
import { TableHeadComponent } from "../../table/tableHead";
import { TableRowComponent } from "../../table/tableRow";
import { TextInput } from "../../textInput";
import colors from '../../utils/index.module.scss';
import { F24Map, F24Typology, FiscalDocumentStatus, StatusMap } from "../dto";
import { findAllFiscalDocumentsF24, findByIdFiscalDocument, findUnseenFiscalDocuments, setFileNameF24Filter, setStatusF24Filter, setTypologyF24Filter } from "../slice";

export function F24List() {
    const dispatch = useAppDispatch()

    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)
    const authState = useAppSelector(state => state.auth)

    const objectService = NewObjectService()

    useEffect(() => {
        dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: authState.findMeResponse?.id.toString() }))
    }, [])

    if (
        fiscalDocumentState.findAllFiscalDocumentF24Status === 'loading' ||
        authState.findMeStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    let fiscalDocumentF24Rows: ReactNode[] = []

    if (fiscalDocumentState.findAllFiscalDocumentF24Status === 'successfully' &&
        fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentF24Response.data !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentF24Response.data.length > 0
    ) {
        fiscalDocumentState.findAllFiscalDocumentF24Response.data.forEach(data => {
            let typology = F24Map.get(data.typology as F24Typology)
            let status = StatusMap.get(data.status)
            fiscalDocumentF24Rows.push(
                <TableRowComponent key={'fiscal-documents-f24-' + data.id}>
                    <TableCellComponent label={data.fileName} cellType={"row-semibold"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={typology !== undefined ? typology : ''} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={status !== undefined ? status : ''} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.expirationDate && format(new Date(data.expirationDate), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.date && format(new Date(data.date), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.lastDownloadDate !== null ? '' : '-'} cellType={"row-regular"} content={data.lastDownloadDate !== null ? <PillComponent label={format(new Date(data.lastDownloadDate), 'dd/MM/yyyy')} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> : ''} alignLabel={"left"} />
                    <TableCellComponent content={
                        <div className="d-flex justify-content-end align-items-center w-100">
                            <InfoTextPopup
                                label={"Scarica"}
                                position="bottom-left"
                                children={
                                    <ButtonComponent
                                        icon={<DownloadIcon colorBase={colors.neutral600} />}
                                        onClick={() => {
                                            dispatch(findFileById(data.objectId.toString())).then((e) => {
                                                //@ts-ignore
                                                objectService.downloadBase64WithExtension(data.fileName, e.payload !== null ? e.payload : '')
                                                dispatch(findByIdFiscalDocument(data.id))
                                                    .then(() => {
                                                        dispatch(setFindDocumentStatus('idle'))
                                                        dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: authState.findMeResponse?.id.toString() }))
                                                        dispatch(findUnseenFiscalDocuments(authState.findMeResponse?.id.toString() || '0'))
                                                    })
                                            });
                                        }}
                                        color={Colors.NEUTRAL}
                                        label=""
                                        variant={Variant.LINK}
                                        size={Size.SM}
                                        iconStyle={IconStyle.ONLY}
                                    />
                                }
                            />
                        </div>
                    } cellType={"row-regular"} alignLabel={"right"} />
                </TableRowComponent>
            )
        })
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            gap: '40px',
            width: '100%'
        }}>
            <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
                    <div className="col-12 col-lg p-0 m-0">
                        <TextInput value={fiscalDocumentState.fiscalDocumentF24Filters.fileName} onChange={(e) => dispatch(setFileNameF24Filter(e.target.value))} id="f24-name" type={"text"} placeholder={"Cerca nome del file"} startIcon={<SearchIcon colorBase={""} />} />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentF24Filters.typology}
                            onChange={(e) => {
                                dispatch(setTypologyF24Filter(e.target.value))
                                dispatch(findAllFiscalDocumentsF24({
                                    typology: e.target.value as F24Typology,
                                    fileName: fiscalDocumentState.fiscalDocumentF24Filters.fileName,
                                    itemsPerPage: fiscalDocumentState.fiscalDocumentF24Filters.itemsPerPage,
                                    page: fiscalDocumentState.fiscalDocumentF24Filters.page,
                                    month: fiscalDocumentState.fiscalDocumentF24Filters.month,
                                    year: fiscalDocumentState.fiscalDocumentF24Filters.year,
                                    status: fiscalDocumentState.fiscalDocumentF24Filters.status,
                                    type: fiscalDocumentState.fiscalDocumentF24Filters.type,
                                    companyId: authState.findMeResponse?.id.toString()
                                }))
                            }}
                            id={"f24-typology"}
                            menuItems={
                                [
                                    <MenuItem key='f24-typology-filter' value=''>Seleziona tipologia</MenuItem>,
                                    <MenuItem key={F24Typology.contributions} value={F24Typology.contributions}>Contributi</MenuItem>,
                                    <MenuItem key={F24Typology.directTax} value={F24Typology.directTax}>Imposte dirette</MenuItem>,
                                    <MenuItem key={F24Typology.iva} value={F24Typology.iva}>IVA</MenuItem>,
                                    <MenuItem key={F24Typology.other} value={F24Typology.other}>Altro</MenuItem>
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentF24Filters.status}
                            onChange={(e) => {
                                dispatch(setStatusF24Filter(e.target.value))
                                dispatch(findAllFiscalDocumentsF24({
                                    typology: fiscalDocumentState.fiscalDocumentF24Filters.typology,
                                    fileName: fiscalDocumentState.fiscalDocumentF24Filters.fileName,
                                    itemsPerPage: fiscalDocumentState.fiscalDocumentF24Filters.itemsPerPage,
                                    page: fiscalDocumentState.fiscalDocumentF24Filters.page,
                                    month: fiscalDocumentState.fiscalDocumentF24Filters.month,
                                    year: fiscalDocumentState.fiscalDocumentF24Filters.year,
                                    status: e.target.value,
                                    type: fiscalDocumentState.fiscalDocumentF24Filters.type,
                                    companyId: authState.findMeResponse?.id.toString()
                                }))
                            }}
                            id={"financial-statements-status"}
                            menuItems={
                                [
                                    <MenuItem key='financial-statements-status-filter' value=''>Seleziona stato</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.toRead} value={FiscalDocumentStatus.toRead}>Da leggere</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.read} value={FiscalDocumentStatus.read}>Letto</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                        <div className="row p-0 m-0" style={{ gap: '8px' }}>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Pulisci"}
                                    onClick={() => {
                                        dispatch(setFileNameF24Filter(''))
                                        dispatch(setTypologyF24Filter('' as F24Typology))
                                        dispatch(setStatusF24Filter(''))

                                        dispatch(findAllFiscalDocumentsF24({
                                            typology: '' as F24Typology,
                                            fileName: '',
                                            itemsPerPage: fiscalDocumentState.fiscalDocumentF24Filters.itemsPerPage,
                                            page: fiscalDocumentState.fiscalDocumentF24Filters.page,
                                            month: fiscalDocumentState.fiscalDocumentF24Filters.month,
                                            year: fiscalDocumentState.fiscalDocumentF24Filters.year,
                                            status: '',
                                            type: fiscalDocumentState.fiscalDocumentF24Filters.type,
                                            companyId: authState.findMeResponse?.id.toString()
                                        }))
                                    }}
                                    color={Colors.NEUTRAL}
                                    variant={Variant.OUTLINE}
                                    size={Size.MD}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Applica"}
                                    onClick={() => {
                                        dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: authState.findMeResponse?.id.toString() }))
                                    }}
                                    color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponent>
                    <TableHeadComponent>
                        <TableCellComponent label={"Nome del file"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Tipologia"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Stato"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Scadenza"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Data invio"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Ultimo download"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                    </TableHeadComponent>
                    <TableBody>
                        {fiscalDocumentF24Rows}
                    </TableBody>
                </TableComponent>
            </div>
        </div>
    )
}