import { useEffect, useState } from "react"
import { IconsProps } from "./dto"

export function DocumentSentIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33326 1.04167C4.72548 1.04167 4.14258 1.28311 3.71281 1.71288C3.28304 2.14266 3.04159 2.72555 3.04159 3.33334V16.6667C3.04159 17.2745 3.28304 17.8574 3.71281 18.2872C4.14258 18.7169 4.72548 18.9584 5.33326 18.9584H15.3333C15.9411 18.9584 16.524 18.7169 16.9537 18.2872C17.3835 17.8574 17.625 17.2745 17.625 16.6667V7.50002C17.625 7.33426 17.5591 7.17529 17.4419 7.05808L11.6086 1.22473C11.4913 1.10752 11.3324 1.04167 11.1666 1.04167H5.33326ZM4.59669 2.59677C4.79204 2.40142 5.057 2.29167 5.33326 2.29167H10.5416V7.50002C10.5416 7.8452 10.8214 8.12502 11.1666 8.12502H16.375V16.6667C16.375 16.943 16.2652 17.2079 16.0699 17.4033C15.8745 17.5986 15.6096 17.7084 15.3333 17.7084H5.33326C5.057 17.7084 4.79204 17.5986 4.59669 17.4033C4.40134 17.2079 4.2916 16.943 4.2916 16.6667V3.33334C4.2916 3.05707 4.40134 2.79212 4.59669 2.59677ZM15.4911 6.87502L11.7916 3.17556V6.87502H15.4911ZM10.9941 15.8334C10.9941 16.1983 10.6982 16.4942 10.3333 16.4942C9.96831 16.4942 9.67244 16.1983 9.67244 15.8334V10.7621L7.46722 12.9673C7.20914 13.2254 6.79073 13.2254 6.53265 12.9673C6.27458 12.7092 6.27458 12.2908 6.53265 12.0328L9.866 8.69941C10.1241 8.44134 10.5425 8.44134 10.8006 8.69941L14.1339 12.0328C14.392 12.2908 14.392 12.7092 14.1339 12.9673C13.8758 13.2254 13.4574 13.2254 13.1993 12.9673L10.9941 10.7621V15.8334Z" fill={color} />
        </svg>
    )
}
