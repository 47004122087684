import { Button } from "@mui/material";
import React, { ReactElement } from "react";
import { OnButtonClick } from "../utils";
import { Colors, IconStyle, Size, Variant } from "./dto";
import { buttonStyle, colorIcon } from "./style";

interface Props {
    label: string
    onClick: OnButtonClick
    color: Colors
    variant: Variant
    size: Size
    iconStyle: IconStyle
    icon?: ReactElement
    disabled?: boolean
}

export function ButtonComponent(props: Props) {
    const { label } = props
    const { color } = props
    const { variant } = props
    const { size } = props
    const { iconStyle } = props
    const { icon } = props
    const { onClick } = props
    const { disabled } = props

    return (
        <div>
            <Button
                disabled={disabled}
                sx={buttonStyle(color, variant, size, iconStyle)} onClick={onClick}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: iconStyle === IconStyle.LEFT ? 'row-reverse' : 'row'
                    }}
                >
                    {iconStyle !== IconStyle.ONLY && <span className="text-overflow">{label}</span>}
                    {iconStyle !== IconStyle.OFF &&
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: iconStyle === IconStyle.RIGHT ? '8px' : 0,
                                marginRight: iconStyle === IconStyle.LEFT ? '8px' : 0
                            }}
                        >
                            {icon && React.cloneElement(icon, { colorBase: colorIcon(color, variant) })}
                        </div>
                    }
                </div>
            </Button>
        </div>
    )
}