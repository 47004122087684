import styled from "@emotion/styled";
import { Menu, MenuProps } from "@mui/material";
import { ReactNode } from "react";
import { MenuKebabIcon } from "../icons/menuKebab";
import colors from '../utils/index.module.scss';

interface Props {
    children?: ReactNode
    anchorEl: HTMLElement | null
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

export const StyledMenu = styled((props: MenuProps) => (
    <Menu
        disableScrollLock
        elevation={0}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(() => ({
    '& .MuiPaper-root': {
        padding: '12px',
        borderRadius: 12,
        marginTop: 12,
        minWidth: 180,
        color: colors.neutral200,
        boxShadow: colors.boxShadowLG,
        '& .MuiMenu-list': {
            padding: '0',
            gap: 4,
            display: 'flex',
            color: colors.neutral200,
            flexDirection: 'column'
        },
        '& .MuiDivider-root': {
            width: '100%',
            height: '1px',
            color: colors.neutral200,
            border: '1px solid ' + colors.neutral100
        },
    },
}));

export function ContextMenuComponent(props: Props) {
    const { children } = props
    const { anchorEl } = props
    const { setAnchorEl } = props

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ width: '24px', margin: '0px !important', display: 'flex', justifyContent: 'flex-end' }}>
            <div
                onClick={handleClick}
            >
                <MenuKebabIcon colorBase={anchorEl !== null ? colors.primary500 : colors.neutral500} />
            </div>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {children}
            </StyledMenu>
        </div>
    )
}