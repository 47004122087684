import { Switch } from "@mui/material";
import { ChangeEvent } from "react";
import style from '../utils/index.module.scss';

interface Props {
    id?: string
    checked?: boolean
    disabled?: boolean
    label?: string
    labelDescription?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export function SwitchComponent(props: Props) {
    const { id } = props;
    const { checked } = props;
    const { disabled } = props;
    const { label } = props;
    const { labelDescription } = props;
    const { onChange } = props;

    return (
        <div style={{
            display: 'flex', alignItems: 'center'
        }}>
            <Switch
                id={id}
                checked={checked}
                disabled={disabled}
                onChange={(e, checked) => onChange && onChange(e, checked)}
                sx={{
                    width: 44,
                    height: 24,
                    padding: 0,
                    borderRadius: '12px',
                    '& .MuiSwitch-switchBase': {
                        padding: 0,
                        margin: 0,
                        transitionDuration: '300ms',
                        '&.Mui-checked': {
                            color: '#fff',
                            '& + .MuiSwitch-track': {
                                backgroundColor: style.primary500,
                                opacity: 1,
                                border: 0,
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 0.5,
                            },
                        },
                        '&.Mui-disabled .MuiSwitch-thumb': {
                            color: 'white',
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.3,
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        width: 20,
                        height: 20,
                        margin: '2px',
                        boxShadow: style.boxShadowXS + ' !important'
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: style.primary100,
                        opacity: 1,
                    }
                }}
            />
            {
                (label || labelDescription) &&
                <p style={{ margin: 0, paddingLeft: '12px' }} className="text text--md typography--medium typography-neutral--500">
                    {label}
                    <br />
                    <span style={{ margin: 0 }} className="text text--sm typography--regular">
                        {labelDescription}
                    </span>
                </p>
            }
        </div>
    )
}