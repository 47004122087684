import { TableRow } from "@mui/material";
import { ReactNode } from "react";

interface Props {
    children: ReactNode
}

export function TableRowComponent(props: Props) {
    const { children } = props

    return (
        <TableRow
            sx={{
                '& td': {
                    border: 0,
                    boxShadow: 'inset 0px -1px 0px #F0F1F4'
                },
                '& td:first-of-type': {
                    paddingLeft: '16px'
                },
                '& td:last-child': {
                    paddinfRight: '16px'
                }
            }}
        >
            {children}
        </TableRow>
    )
}